import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import WidgetConfigurationLayout from '../WidgetConfigurationLayout';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';

const EnquiryForm = () => {
    const { state, dispatch } = useContext(SmartContext);
   
    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions } });
    }, []);

    // Use a ref to keep track of whether this is the initial render
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            // Skip running the effect on the initial render
            isInitialRender.current = false;
            return;
        }

        let openButtonAlignment = 'center-right';

        if ( state?.data?.widgetData?.openButtonLayout == 'horizontal' ) {
            openButtonAlignment = 'bottom-right';
        }

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    widgetData: {
                        ...state.data.widgetData,
                        openButtonAlignment
                    }
                }
            }
        });

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, widgetData: {...state.data.widgetData, openButtonAlignment: openButtonAlignment}}
            }
        });
    }, [state.data.widgetData.openButtonLayout])

    useEffect(() => {
        let displayPrograms: any  = [];
        if (state.data.formConfig?.programsInterestedIn) {
            state.data.formConfig?.applicablePrograms?.map((programId: any, index: any) => {
                const programName = getDomainValueForCode(programId, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
                displayPrograms.push(programName);
            })

            // Add custom programs to the displayPrograms array
            if (state.data.formConfig?.addMoreFlag) {
                const customProgramNames = state.data.formConfig?.customPrograms?.map((customProgram: any) => customProgram.program?.trim() || "") || [];
                displayPrograms = displayPrograms.concat(customProgramNames.filter((item: string) => item.length > 0));
            }
        }
        
        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, formConfig: {...state.data.formConfig, displayPrograms: displayPrograms}}
            }
        });
    }, [state.data.formConfig.applicablePrograms, state.data.formConfig.customPrograms, state.data.formConfig.addMoreFlag, state.data.formConfig.programsInterestedIn])
    
    return (
        <WidgetConfigurationLayout state={state} >{!state.flags.isDataLoading && <PageBuilder />}</WidgetConfigurationLayout>
    );
};

export default EnquiryForm;