import { axiosClient } from '../../../library/Service/axiosClient';
import { reportConfig } from './report-config';
import pageConfig from './PageConfig.json';

class AlumniEngagementService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = AlumniEngagementService.getDefaultSearchCriteria();

        return Promise.all([
            AlumniEngagementService.getPageConfig(),
            AlumniEngagementService.search(searchCriteria, params.pageName),
           // AlumniEngagementService.getSchoolSearchFilterDomains(params.pageName),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria },
                //filterDomains: values[2].data,
                reportConfig: reportConfig[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, reportType: string): any {
        const payload = AlumniEngagementService.sanitizeSearchCriteria(searchCriteria);
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/user/manage-alumni-engagement`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        const criteria = { ...searchCriteria };
        for (const [key, value] of Object.entries(searchCriteria)) {
            criteria[key] = Array.isArray(value) && typeof value[0] === 'object' ? value.map((obj) => Object.values(obj)[0]) : value;
        }
        return criteria;
    }

    static searchCount(searchCriteria: any, pageName: string) {
        const criteria = this.sanitizeSearchCriteria(searchCriteria);
        return axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/user/all-alumniengagement-count`, criteria);
    }

    // static getSchoolSearchFilterDomains(reportName: string = '') {
    //     return axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/school-group-search-filter-domains`);
    // }

    static getDefaultSearchCriteria(): any {
        return {
            passOutYear: [],
            instituteId: '',
            programId: [],
            schoolGroupId : [],
            educationOrEmploymentCurrentStatusCode: [],
            courseLevelCode: [],
            universityId: [],
            isPrestigiousCollege: [],
            collegeCountryCode: [],
            hasReceivedScholarship: '0',
            hasAppliedUgEntranceTest: '0',
            hasRepliedToMsg:'0',
            hasUploadedGuidanceVideos:'0',
            hasWorkDtlAdded: '0',
            seniorityLevelCode:[],
            residingCountryCode:[],
            typeCode:[],
            conversationFromDate: [],
            conversationToDate: '',
            lastprofileSentFromDate:[],
            lastprofileSentToDate:'',
            // userStatusCode:'ACTIVE',
            presentEducationStatusCode:[],
            workingStatusCode:[],
        };
    }
}

export default AlumniEngagementService;
