import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../engagement-list/useManageAlumni';
import { FormSection } from '../../../library/Core/SmartTypes';
import FormBuilder from '../../../library/Builders/FormBuilder';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';
import ConversationReportService from './ConversationReportService';


const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);

    const isFirstRender = useRef(true);

    const handleClearAll = () => {
        var defaultSearchCriteria = ConversationReportService.getDefaultSearchCriteria();
        defaultSearchCriteria.senderType = []

        var buttons = document.querySelectorAll('.row.g-3 button');
        buttons.forEach(function (button) {
            button.textContent = '';
        });
        document.querySelectorAll('.css-1dimb5e-singleValue').forEach(element => {
            element.textContent = ''; 
        });
        setStateValue('searchCriteria', defaultSearchCriteria);
    };


    useEffect(() => {
        ConversationReportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
            setStateValue('alumniList', response.data);
            fetchconversationCount(state);
        });
    }, [state.data.searchCriteria]);

    const fetchconversationCount = async (state:any) => {
        try {
            const conversationCount = await ConversationReportService.searchCount(state.data.searchCriteria, state.routeInfo.pageName);
                    console.log("conversationCount",conversationCount)         
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key:"conversationCount", value:conversationCount?.data },
            });

            const TwowayconversationCount = await ConversationReportService.searchTwowayconversationCount(state.data.searchCriteria, state.routeInfo.pageName);
                                        
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key:"twoWayconversationCount", value:TwowayconversationCount?.data },
            });
        } catch (error) {
            console.error("Error fetching alumni count:", error);
        }
    }

    return (
        <>
            <div className="fitergroup mb-3">
                <div id="collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body p-4">
                        <div className="row g-3">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                     
                    </div>
                </div>
            </div>
            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div>
        </>
    );
};

export default SearchCriteria;
