import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import SessionContext from '../../../library/Core/SessionContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
import { getinstituteList } from '../../../library/Service/domainService';


const AudienceCategory = () => {

    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const userType = sessionState?.userTypeCode;
    const userId = sessionState?.id;
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get('id');

    
    console.log(eventId)
    if(!eventId){
        navigate(`/event/addnew`);
    }

    const { globalState } = useContext(GlobalContext);
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleCreate,
        },
    } as any);

    function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
    
        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });


        if (!isFormValid(state, dispatch)) {
            //dispatch({ type: 'SHOW_ERRORS' });
            return;
        }

        let updateaudience = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updateaudienceinfo`;
        

        axiosClient()
            .post(`${updateaudience}`, {
                eventId : eventId,
                eventaudience: JSON.stringify(state['data']['eventaudience']),
                
                 
             })
            .then((res: any) => {
                console.log(res.data.eventId)
                alert(res.data.message)

                if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
                navigate(`/event/theme-options?id=${res.data.eventId}`);
            });
    }


 

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { eventaudience: data },
                domain,
                routeInfo,
            },
        });
    }, []);

    useEffect(() => {
        if(state.data?.user?.instituteId != undefined){
            // alert('Test')
            const fetchData = async () => {
                try {
                    const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/user/get-programs-by-institute-id`, {
                        institute_id: state.data?.user?.instituteId,
                    });
                    const programs = response.data.data.programs;
                    let program_values = [];
                    for(let i = 0; i < programs.length;i++)
                    {
                        program_values[i] = {'label' : programs[i]['name'],'value': programs[i]['id']};
                    }
                    
                    dispatch({
                        type: 'SET_DOMAIN',
                        payload: { key: 'INSTITUTE_ATTENDED_PROGRAM_CODE', value: getinstituteList('INSTITUTE_ATTENDED_PROGRAM_CODE', program_values) },
                    });
                } catch (error) {
                    console.error('Error fetching program data:', error);
                }
            }

            fetchData();
        }
    }, [state.data?.user?.instituteId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default AudienceCategory;
