import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import LoadingSpinner from '../../../library/SimpleControls/LoadingSpinner';
import EventFilters from './EventFilters';
import EventTabs from './EventTabs';
import EventsGridControl from './EventsGridControl';
import { useNavigate } from 'react-router-dom';

const ImgAddIcon = `${process.env.REACT_APP_IMAGE_BASEPATH}/add.svg`;

const EventsLayout = () => {
    const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();

    if (state.flags.isDataLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div key={state.data.searchBy} className="main flex-1">
            <section className="notificationSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius h-100 p-0 pt-2">
                                <DebugBarControl />
                                <div className="m-w-90 d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 pt-4 pb-0">
                                    <h3
                                        className="font-35 font-600 mb-0 mt-3 block-title aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay="600">
                                        Meetings & Events
                                    </h3>
                                    
                                    <div className='col-md-auto'>                                            
                                        <div className="dashboard innerpage smart-scroll scrolled-down">                                                
                                            <a href="javascript:;" onClick={() => {navigate('/event/add')}} className="btn btn-green height-40 d-inline-flex text-white font-14 px-3 m-0 text-decoration-none"><span className="d-flex align-items-center"><img src={ImgAddIcon} className="me-2" alt=""/>Add Event</span></a>
                                        </div>
                                    </div>
                                </div>
                                <EventTabs />
                                <div className="row m-0 px-3 px-sm-4 pt-4 pb-5 mt-0 JobInternshipListing">
                                    <EventFilters />
                                    <EventsGridControl />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <DebugControl /> */}
            </section>
        </div>
    );
};

export default EventsLayout;
