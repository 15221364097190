import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    // let customValidationPassed = true;
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate)) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let professionalPractice = state?.data['professionalPractice'];
    console.log(globalState);

    const IndustryData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => {
            //console.log('Value during filter:', value);
            return value.some((item: any) => item.categoryCode === 'INDUSTRY_CODE');
        })
        .reduce((result: any, [key, value]: any) => {
            // Assuming each 'value' is an array, iterate over it
            value.forEach((item: any) => {
                if (item.categoryCode === 'INDUSTRY_CODE') {
                    console.log('Item during reduce:', item); // Log each item that matches 'INDUSTRY_CODE'
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {}) as any;

    const functionalData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => {
            //console.log('Value during filter:', value);
            return value.some((item: any) => item.categoryCode === 'FUNCTIONAL_AREA_CODE');
        })
        .reduce((result: any, [key, value]: any) => {
            // Assuming each 'value' is an array, iterate over it
            value.forEach((item: any) => {
                if (item.categoryCode === 'FUNCTIONAL_AREA_CODE') {
                    console.log('Item during reduce:', item); // Log each item that matches 'INDUSTRY_CODE'
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {}) as any;

    // object to map type of practice with Industry
    const industryMapperWithPractice = {
        DOCTOR: 'Healthcare and Life Sciences',
        DENTIST: 'Healthcare and Life Sciences',
        VETERINARIAN: 'Healthcare and Life Sciences',
        PSYSIOTHERAPY: 'Healthcare and Life Sciences',
        CA: 'Accounting, Auditing and Financial Services',
        LEGAL: 'Law and Legal Consulting',
        ARCHITECT: 'Infrastructure and Construction',
        INSURANCE_AGENT: 'BFSI',
        REAL_ESTATE: 'Real Estate',
    };

    // object to map type of practice with Industry
    const functionalAreaMapperWithPractice = {
        DOCTOR: 'Diagnosis and Treatment',
        DENTIST: 'Diagnosis and Treatment',
        VETERINARIAN: 'Diagnosis and Treatment',
        PSYSIOTHERAPY: 'Diagnosis and Treatment',
        CA: 'Finance and Accounts',
        LEGAL: 'Legal',
        ARCHITECT: 'Engineering and Design',
        INSURANCE_AGENT: 'Sales and BD',
        REAL_ESTATE: 'Sales and BD',
    };

    // assigne the value with type of industryMapperWithPractice
    const typeOfPractice = professionalPractice?.typeOfPractice as keyof typeof industryMapperWithPractice;
    // Use the industryMapperWithPractice to find the corresponding industry
    const mappedIndustry = industryMapperWithPractice[typeOfPractice];
    console.log('mappedIndustry' + mappedIndustry);
    const industryCode = IndustryData[mappedIndustry];

    const typeOfPracticeForFunctionalArea = professionalPractice?.typeOfPractice as keyof typeof functionalAreaMapperWithPractice;
    const mappedFunctionalArea = functionalAreaMapperWithPractice[typeOfPracticeForFunctionalArea];
    const functionalAreaCode = functionalData[mappedFunctionalArea];

    let jobs = [
        {
            ...professionalPractice,
            recordTypeCode: 'JOINED',
            jobCategory: 'PROFESSIONAL_PRACTICE',
            isOutcome: true,
            industryCode: industryCode,
            functionalAreaCode: functionalAreaCode,
        },
    ];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobs,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const professionalPractice = state.data.professionalPractice;

    if (isEmpty(professionalPractice?.companyId) && isEmpty(professionalPractice?.otherCompany)) {
        isFormInvalid = true;
        setError(`professionalPractice.companyId`, [`Please enter "Name of the Company"`], dispatch);
    } else {
        setError(`professionalPractice.companyId`, [], dispatch);
    }

    // jobs.forEach((job: any, index: number) => {
    //     if (state?.data?.profile?.appliedForThePlacement) {
    //         if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
    //             isFormInvalid = true;
    //             setError(`jobsApplied.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
    //         } else {
    //             setError(`jobsApplied.${index}.companyId`, [], dispatch);
    //         }
    //     } else {
    //         setError(`jobsApplied.${index}.companyId`, [], dispatch); //clear error appliedForThePlacement set to false
    //     }
    // });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        // dispatch({
        //     type: 'SET_FIELD_VALIDATION_ERRORS',
        //     payload: { dataKey: 'jobsApplied', errorMessages },
        // });
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'professionalPractice', errorMessages },
        });
    }

    return !isFormInvalid;
};

// export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
//     const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
//     const jobs = props.state.data.jobs.map((row: any, index: number) => {
//         return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
//     });

//     props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

//     props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'jobs', value: jobs } });
// };

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    // if (!props.state.data.teachingWork?.isCurrent) {
    //     props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.industryCode`, value: [] } });
    // }
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const job = state?.data['professionalPractice'];
    let isFormValid = true;

    if (isEmpty(job?.startDate)) {
        setError(`professionalPractice.startDate`, [`Please enter "Start Date" `], dispatch);
        isFormValid = false;
    } else {
        setError(`professionalPractice.startDate`, [], dispatch);
    }

    // if ((job?.isCurrent === false || isEmpty(job?.isCurrent)) && isEmpty(job?.endDate)) {
    //     setError(`entreprenurship.endDate`, [`Please enter "End Date" `], dispatch);
    //     isFormValid = false;
    // } else {
    //     setError(`entreprenurship.endDate`, [], dispatch);
    // }

    if (new Date(job?.startDate) >= new Date(job?.endDate)) {
        console.log('Invalid date');
    }

    if (job?.isCurrent === false || isEmpty(job?.isCurrent)) {
        if (new Date(job?.startDate) > new Date(job?.endDate)) {
            setError(`entreprenurship.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
            isFormValid = false;
        } else {
            setError(`entreprenurship.endDate`, [], dispatch);
        }
    }

    return isFormValid;
};
