import moment from 'moment';
import StudentService from '../../services/student.service';
import { getFlexColDomainValueForCode, isEmpty } from '../Core/SmartFunctions';
import { DomainElement, State } from '../Core/SmartTypes';

let cYear = moment().year();

export const getDomainElementsForGivenYearRange = (categoryCode: string, startYear: number = 1970, endYear: number = cYear) => {
    const passOutYearDomain: DomainElement[] = [];
    let order = 1; // Initialize the order variable

    for (let i = endYear; i >= startYear; i--) {
        passOutYearDomain.push({
            categoryCode,
            code: i.toString(),
            value: i.toString(),
            parentCode: '',
            displayOrder: order,
        });
        order++;
    }
    return passOutYearDomain;
};

// export const getStudentProgramStatusDomain = () => {
//     return [
//         { categoryCode: 'USER_TYPE_CODE', code: 'STUDENT', value: 'Pursuing', parentCode: '', displayOrder: 1 },
//         { categoryCode: 'USER_TYPE_CODE', code: 'ALUMNI', value: 'Completed', parentCode: '', displayOrder: 2 },
//     ];
// };

export const getInstituteProgramDomain = (programs: any) => {
    return programs.map((program: any) => ({
        code: program.id,
        value: program.name,
        categoryCode: 'INSTITUTE_PROGRAM_CODE',
        parentId: program?.instituteId,
        displayOrder: 1,
    }));
};

/**
 * Get the year diffrence with start year - n year
 * @param categoryCode
 * @param startYear
 * @param endYear
 * @returns
 */
export const getDomainWithYearRange = (categoryCode: string, startYear: number, endYear: number) => {
    const passOutYearDomain: DomainElement[] = [];
    let order = 1; // Initialize the order variable

    for (let i = startYear; i > endYear; i--) {
        passOutYearDomain.push({
            categoryCode,
            code: i.toString(),
            value: i.toString(),
            parentCode: '',
            displayOrder: order,
        });
        order++;
    }
    return passOutYearDomain;
};

export const enum DomainFlexColumEnum {
    EXAM_MAX_MARKS = 'flexCol1',
}

const getPassOutYear = (categoryCode: string): DomainElement[] => {
    const foundingYear = parseInt((sessionStorage.getItem('school-founding-year') as string) ?? 2000);
    if (isNaN(foundingYear)) return [];
    return getDomainElementsForGivenYearRange(categoryCode, foundingYear, moment().year());
};

export const addUserSpecificDomains = (domain: Map<string, DomainElement[]>, student: any) => {
    // const user = JSON.parse(sessionStorage.getItem('user') as string);
    // const user = JSON.parse(localStorage.getItem('user') as string);
    // const { instituteFoundingYear } = user;

    const instituteFoundingYear = student?.selectedInstitute?.instituteFoundingYear;

    // TODO: Track domain additions and store in session so that these domains are not generated for the subsequent pages (or) convert domain into local storage
    let categoryCode = 'UG_START_YEAR_CODE';
    // Commenting this as it is not fetching correct code for passout year  for UG in case prohram in user institute is of PG
    //const studentPassOutYear = StudentService.getStudentPassOutYear(student);
    const studentPassOutYear = 1990;

    const studentUGEndYear = student?.underGradCollegeJoined?.endYear ?? studentPassOutYear;

    if (!domain.has(categoryCode)) {
        const ugPgStartYearDomain = getDomainElementsForGivenYearRange(categoryCode, studentPassOutYear, moment().year());
        if (ugPgStartYearDomain.length > 0) domain.set(categoryCode, ugPgStartYearDomain);
    }

    categoryCode = 'UG_END_YEAR_CODE';
    if (!domain.has(categoryCode)) {
        const ugStartYear = student?.underGradCollegeJoined?.startYear ?? studentPassOutYear;
        const ugPgEndYearDomain = getDomainElementsForGivenYearRange(categoryCode, ugStartYear + 3, ugStartYear + 5);
        if (ugPgEndYearDomain.length > 0) domain.set(categoryCode, ugPgEndYearDomain);
    }

    categoryCode = 'INSTITUTE_START_YEAR_CODE';
    if (!domain.has(categoryCode)) {
        // const instituteStartYear = student?.underGradCollegeJoined?.startYear ?? studentPassOutYear;
        const instituteStartYearDomain = getDomainElementsForGivenYearRange(categoryCode, instituteFoundingYear, moment().year());
        if (instituteStartYearDomain.length > 0) domain.set(categoryCode, instituteStartYearDomain);
    }

    categoryCode = 'INSTITUTE_PASS_OUT_YEAR_CODE';
    if (!domain.has(categoryCode)) {
        const startYear = student?.selectedInstitute?.startYear ?? instituteFoundingYear;
        const institutePassOutYearDomain = getDomainElementsForGivenYearRange(categoryCode, startYear, moment().year());
        if (institutePassOutYearDomain.length > 0) domain.set(categoryCode, institutePassOutYearDomain);
    }

    categoryCode = 'PG_START_YEAR_CODE';
    if (!domain.has(categoryCode) && !isEmpty(student.underGradColleges)) {
        let ugEndYear =
            student.underGradColleges.find((college: any) => college.recordTypeCode === 'JOINED')?.endYear ?? studentPassOutYear;
        // if (ugEndYear > moment().year()) ugEndYear = moment().year();
        const pgStartYearDomain = getDomainElementsForGivenYearRange(categoryCode, ugEndYear, cYear) ?? [];
        // if (pgStartYearDomain.length > 0) domain.set(categoryCode, pgStartYearDomain);
        domain.set(categoryCode, pgStartYearDomain);
    } else {
        let instFlexData = getFlexColDomainValueForCode(student?.postGradCollege?.[0]?.universityId, 'INSTITUTE_DOMAIN', {
            domain: domain,
        } as State);
        let foundingYear = isEmpty(instFlexData) ? '1970' : JSON.parse(instFlexData).founding_year;
        const pgStartYearDomain = getDomainElementsForGivenYearRange(categoryCode, foundingYear, cYear) ?? [];
        domain.set(categoryCode, pgStartYearDomain);
    }

    categoryCode = 'PG_END_YEAR_CODE';
    if (!domain.has(categoryCode) && !isEmpty(student.underGradColleges)) {
        let ugEndYear =
            student.underGradColleges.find((college: any) => college.recordTypeCode === 'JOINED')?.endYear ?? studentPassOutYear;
        // if (ugEndYear > moment().year()) ugEndYear = moment().year();
        const pgEndYearDomain = getDomainElementsForGivenYearRange(categoryCode, ugEndYear, cYear + 3) ?? [];
        // if (pgEndYearDomain.length > 0) domain.set(categoryCode, pgEndYearDomain);
        domain.set(categoryCode, pgEndYearDomain);
    } else {
        let instFlexData = getFlexColDomainValueForCode(student?.postGradCollege?.[0]?.universityId, 'INSTITUTE_DOMAIN', {
            domain: domain,
        } as State);
        let foundingYear = isEmpty(instFlexData) ? '1970' : JSON.parse(instFlexData).founding_year;
        const pgEndYearDomain = getDomainElementsForGivenYearRange(categoryCode, foundingYear, cYear) ?? [];
        domain.set(categoryCode, pgEndYearDomain);
    }

    categoryCode = 'UG_ENTRANCE_EXAM_TAKEN_YEAR_CODE';
    if (!domain.has(categoryCode) && !isEmpty(student.underGradColleges)) {
        let ugJoinYear = parseInt(
            student.underGradColleges.find((college: any) => college.recordTypeCode === 'JOINED')?.startYear || cYear
        );
        const pgUGExamTakenYearDomain = getDomainElementsForGivenYearRange(categoryCode, studentPassOutYear, ugJoinYear);
        if (pgUGExamTakenYearDomain.length > 0) domain.set(categoryCode, pgUGExamTakenYearDomain);
    } else {
        const pgUGExamTakenYearDomain = getDomainElementsForGivenYearRange(categoryCode, 1970, cYear);
        if (pgUGExamTakenYearDomain.length > 0) domain.set(categoryCode, pgUGExamTakenYearDomain);
    }

    categoryCode = 'PG_HIGHER_STUDY_START_ON';
    if (!domain.has(categoryCode)) {
        const currentYear = moment().year();
        const higherStudyStartOnDomain = [];
        // @AGS-399-start @dt 3rd.Aug,2K23 @by RG;
        // const quarters = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec']; // Corrected 'Jul-Aug' to 'Jul-Sep' as a quarter should be three months
        const quarters = ['Jan-Mar', 'Apr-Jun', 'Jul-Aug', 'Sep-Dec'];
        // @AGS-399-end;

        // @AGS-399-start @dt 3rd.Aug,2K23 @by RG;
        for (let i = 0; i <= 2; i++) {
            // for (let i = 2; i <= 4; i++) {
            // @AGS-399-end;
            for (let j = 0; j <= 3; j++) {
                higherStudyStartOnDomain.push({
                    categoryCode,
                    code: `${currentYear + i}-Q${j + 1}`, // j+1 will give quarters as Q1, Q2, Q3 and Q4.
                    value: `${currentYear + i} - ${quarters[j]}`,
                    parentCode: '',
                    displayOrder: i,
                });
            }
        }

        // for (let i = studentUGEndYear; i < studentUGEndYear + 3; i++) {
        //     for (let j = 0; j <= 3; j++) {
        //         higherStudyStartOnDomain.push({
        //             categoryCode,
        //             code: `${i}-Q${j + 1}`, // j+1 will give quarters as Q1, Q2, Q3 and Q4.
        //             value: `${i} - ${quarters[j]}`,
        //             parentCode: '',
        //             displayOrder: i,
        //         });
        //     }
        // }
        if (higherStudyStartOnDomain.length > 0) domain.set(categoryCode, higherStudyStartOnDomain);
    }

    categoryCode = 'PASS_OUT_YEAR_CODE';
    if (!domain.has(categoryCode)) {
        const passOutYearDomain = getDomainElementsForGivenYearRange(
            categoryCode,
            parseInt(sessionStorage.getItem('school-founding-year') as string),
            moment().year()
        );
        if (passOutYearDomain.length > 0) domain.set(categoryCode, passOutYearDomain);
    }

    categoryCode = 'PAST_YEAR_CODE';
    if (!domain.has(categoryCode)) {
        const pastYearDomain = getPassOutYear(categoryCode);
        if (pastYearDomain.length > 0) domain.set(categoryCode, pastYearDomain);
    }

    categoryCode = 'BREAK_END_YEAR_CODE';
    if (!domain.has(categoryCode) && !isEmpty(student.alumniBreak)) {
        const alumniBreakFromDomain = getDomainElementsForGivenYearRange(categoryCode, student.alumniBreak?.fromDate, cYear);
        if (alumniBreakFromDomain.length > 0) domain.set(categoryCode, alumniBreakFromDomain);
    }

    return domain;
};

export const getAllDomain = (categoryCode: string, domain: any) => {
    const allMasterDomain: DomainElement[] = [];
    let order = 1; // Initialize the order variable

    for (let i = 1; i <= domain.length; i++) {
        allMasterDomain.push({
            categoryCode,
            code: domain[i],
            value: domain[i],
            parentCode: '',
            displayOrder: i,
        });
        //order++;
    }
    return allMasterDomain;
};

export const getinstituteList = (categoryCode: string, domain: any) => {
    const allInstituteDomain: DomainElement[] = [];
    for (let i = 0; i < domain.length; i++) {
        if (domain[i]) {
            allInstituteDomain.push({
                categoryCode,
                code: domain[i]['value'] || '',
                value: domain[i]['label'] || '',
                parentCode: '',
                displayOrder: i + 1,
            });
        }
    }
    return allInstituteDomain;
};

export const getProgramLevelDomainForSchooling = (categoryCode: string, domain: any) => {
    const allProgramLevelDomain: DomainElement[] = [];
    for (let i = 1; i < domain.length; i++) {
        allProgramLevelDomain.push({
            categoryCode,
            code: i.toString(),
            value: i.toString(),
            parentCode: '',
            displayOrder: i + 1,
        });
    }
    return allProgramLevelDomain;
};

// export const addUgEndYearForSelectedStartYear = (categoryCode: string, startYear: number, endYear: number, state: State) => {
//     state?.domain?.set(categoryCode, getDomainElementsForGivenYearRange(categoryCode, startYear, endYear));
// };
