import AlumniGridWithCardsControl from '../../features/alumni/alumni-directory/AlumniGridWithCardsControl';
import GridCardsControl from '../ComplexControls/GridCardsControl';
import TableControl from '../ComplexControls/TableControl';
import ActionIconGroup from '../SimpleControls/ActionIconGroup';
import AlbumPhotoUploderControl from '../SimpleControls/AlbumPhotoUploderControl';
import AmountWithCurrencyControl from '../SimpleControls/AmountWithCurrencyControl';
import ButtonControl from '../SimpleControls/ButtonControl';
import ButtonPalletControl from '../SimpleControls/ButtonPalletControl';
import CambridgeGrade from '../SimpleControls/CambridgeGrade';
import CheckBoxControl from '../SimpleControls/CheckBoxControl';
import CheckBoxYesNoControl from '../SimpleControls/CheckBoxYesNoControl';
import DateAndMonthSelector from '../SimpleControls/DateAndMonthSelector';
import DateRangeSelectorControl from '../SimpleControls/DateRangeSelectorControl';
import DateTimeSelectorControl from '../SimpleControls/DateTimeSelectorControl';
import EntranceTestAndResultControl from '../SimpleControls/EntranceTestAndResultControl';
import FileControl from '../SimpleControls/FileController';
import FileUploader from '../SimpleControls/FileUploader';
import FiltersApplied from '../SimpleControls/FiltersApplied';
import FiltersControl from '../SimpleControls/FiltersControl';
import FreeFormGrid from '../SimpleControls/FreeFormGrid';
import GridWithRows from '../SimpleControls/GridWithRows';
import ListControl from '../SimpleControls/ListControl';
import MultiSelectControl from '../SimpleControls/MultiSelectControl';
import MultiSelectCustomControl from '../SimpleControls/MultiSelectCustomControl';
import MultiSelectCustomControl2 from '../SimpleControls/MultiSelectCustomControl2';
import MultiSelectWithAdditionalInputsDynamic from '../SimpleControls/MultiSelectWithAdditionalAttributesDynamic';
import MultiSelectWithAdditionalInputsTwo from '../SimpleControls/MultiSelectWithAdditionalAttributesTwo';
import MultiSelectWithAdditionalInputsOne from '../SimpleControls/MultiSelectWithAdditionalInputsOne';
import NotifyMeCardsControl from '../SimpleControls/NotifyMeCardsControl';
import NumberRangeSelectorControl from '../SimpleControls/NumberRangePickerControl';
import PhoneControl from '../SimpleControls/PhoneControl';
import RadioControl from '../SimpleControls/RadioControl';
import SearchAsYouTypeInput from '../SimpleControls/SearchAsYouTypeInput';
import SelectControl from '../SimpleControls/SelectControl';
import SelectControlV2 from '../SimpleControls/SelectControlV2';
import SelectControlV3 from '../SimpleControls/SelectControlV3';
import SelectEditable from '../SimpleControls/SelectEditable';
import SelectEditableOnchange from '../SimpleControls/SelectEditableOnchange';
import SocialNetworkControl from '../SimpleControls/SocialNetworkControl';
import SpacerControl from '../SimpleControls/SpacerControl';
import StarControl from '../SimpleControls/StarControl';
import SwitchControl from '../SimpleControls/SwitchControl';
import TextAreaControl from '../SimpleControls/TextAreaControl';
import TextAsLabelControl from '../SimpleControls/TextAsLabelControl';
import TextControl from '../SimpleControls/TextControl';
import TimeSlotsControl from '../SimpleControls/TimeSlotsControl';
import TopFilterPillsControl from '../SimpleControls/TopFilterPillsControl';
import YearAndMonthSelector from '../SimpleControls/YearAndMonthSelector';
import YearRangePicker from '../SimpleControls/YearRangePicker';
import { ChildWrapperControl } from './ChildWrapperControl';
import { evaluateExpression, getControlValueFromState, isEmpty } from './SmartFunctions';
import { FormControl, State } from './SmartTypes';
import FileActionController from '../SimpleControls/FileActionController';
import YearSelector from '../SimpleControls/YearSelector';
import SelectControlV4 from '../SimpleControls/SelectControlV4';
import SwitchInGridControl from '../SimpleControls/SwitchInGridControl';
import FileAction from '../SimpleControls/FileAction';
import FileActionDocs from '../SimpleControls/FileActionDocs';
import SmartFormGrid from '../SimpleControls/SmartFormGrid';
import SmartFileUploader from '../SimpleControls/SmartFileUploader';
import SingleCheckBoxControl from '../SimpleControls/SingleCheckBoxControl';
import SwitchWithInlineLabelControl from '../SimpleControls/SwitchWithInlineLabelControl';
import RichTextAreaControl from '../SimpleControls/RichTextAreaControl';
import SelectEditableMulti from '../SimpleControls/SelectEditableMulti';

export const getControlFromFactory = (
    control: FormControl,
    dataKey: string,
    childDataKey: string,
    state: State,
    handleChangeEvent: any = undefined,
    padding: string = '',
    customStyle: string = ''
) => {
    // TODO: Key should be undefined and randomUUID should not used.
    // const keyVal = `${childDataKey}.${control?.id === undefined ? crypto.randomUUID() : control.id}`;
    // const keyVal = `${childDataKey}.${control?.id === undefined ? uuidv4() : control.id}`;
    const keyVal = `${childDataKey}.${control.id}`;
    let element;
    // logger.log(`Generating: ${control.id}, ControlName: ${control?.props?.label}, DataKey: ${childDataKey} `);

    const isHidden = evaluateExpression(control.hideExpression, state?.data, getControlValueFromState(dataKey as string, state as State));
    // console.log(`Generating: ${control.id}, DataKey: ${childDataKey} `);

    switch (control.type) {
        case 'TEXT':
            element = (
                <TextControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SEARCH_AS_YOU_TYPE':
            element = (
                <SearchAsYouTypeInput
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SPACER':
            element = (
                <SpacerControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'FILE_UPLOAD':
            element = (
                <FileUploader
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'FILE':
            element = (
                <FileControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'FILE_ACTION':
            element = (
                <FileActionController
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'FILE_UPLOAD_IMG_DOCS':
            element = (
                <FileAction
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'FILE_UPLOAD_DOCS':
                element = (
                    <FileActionDocs
                        key={keyVal}
                        control={control}
                        dataKey={childDataKey}
                        parentDataKey={dataKey}
                        handleChangeEvent={handleChangeEvent}
                    />
                );
                break;
        case 'TEXT_AS_LABEL':
            element = (
                <TextAsLabelControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'TEXTAREA':
            element = (
                <TextAreaControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'RICH_TEXTAREA':
            element = (
                <RichTextAreaControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'RADIO':
            element = (
                <RadioControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SWITCH':
            element = (
                <SwitchControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SWITCH_IN_GRID':
            element = (
                <SwitchInGridControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SWITCH_WITH_INLINE_LABEL':
            element = (
                <SwitchWithInlineLabelControl
                key={keyVal}
                control={control}
                dataKey={childDataKey}
                parentDataKey={dataKey}
                handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'CHECK':
            element = (
                <CheckBoxControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'CHECK_YES_NO':
            element = (
                <CheckBoxYesNoControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'SINGLE_CHECK_BOX':
            element = (
                <SingleCheckBoxControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        
        case 'STAR':
            element = (
                <StarControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SELECT':
            element = (
                <SelectControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SELECT_V2':
            element = (
                <SelectControlV2
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    handleChangeEvent={handleChangeEvent}
                    parentDataKey={dataKey}
                />
            );
            break;
        case 'MULTI_SELECT':
            element = (
                <MultiSelectControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'MULTI_SELECT_CUSTOM_CONTROL':
            element = (
                <MultiSelectCustomControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'MULTI_SELECT_CUSTOM_CONTROL_2':
            element = (
                <MultiSelectCustomControl2
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'LIST':
            element = (
                <ListControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'PHONE':
            element = (
                <PhoneControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'YEAR_AND_MONTH':
            element = (
                <YearAndMonthSelector
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'YEAR_PICKER':
            element = (
                <YearSelector
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'YEAR_RANGE_PICKER':
            element = (
                <YearRangePicker
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'NUMBER_RANGE_PICKER':
            element = (
                <NumberRangeSelectorControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'DATE_MONTH_PICKER':
            element = (
                <DateAndMonthSelector
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'DATE_RANGE':
            element = (
                <DateRangeSelectorControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'DATE_TIME_RANGE':
            element = (
                <DateTimeSelectorControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'TIME_SLOTS':
            element = (
                <TimeSlotsControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'CAMBRIDGE_CONTROL':
            element = (
                <CambridgeGrade
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'AMOUNT_WITH_CURRENCY':
            element = (
                <AmountWithCurrencyControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'ENTRANCE_TEST_AND_RESULT':
            element = (
                <EntranceTestAndResultControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'SOCIAL_NETWORK_PROFILE_GRID':
            element = (
                <SocialNetworkControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'FREE_FORM_GRID':
            element = (
                <FreeFormGrid
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'GRID_WITH_ROWS':
            element = (
                <GridWithRows
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'BUTTON_PALLET':
            element = <ButtonPalletControl key={keyVal} control={control} dataKey={childDataKey} handleChangeEvent={handleChangeEvent} />;
            break;
        case 'BUTTON':
            element = (
                <ButtonControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    handleChangeEvent={handleChangeEvent}
                    parentDataKey={dataKey}
                />
            );
            break;
        case 'MULTI_SELECT_WAI_1':
            element = (
                <MultiSelectWithAdditionalInputsOne
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    handleChangeEvent={handleChangeEvent}
                    parentDataKey={dataKey}
                />
            );
            break;
        case 'MULTI_SELECT_WAI_2':
            element = (
                <MultiSelectWithAdditionalInputsTwo
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    handleChangeEvent={handleChangeEvent}
                    parentDataKey={dataKey}
                />
            );
            break;
        case 'MULTI_SELECT_WAI_3':
            element = (
                <MultiSelectWithAdditionalInputsDynamic
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    handleChangeEvent={handleChangeEvent}
                    parentDataKey={dataKey}
                />
            );
            break;
        case 'SEARCH_FILTER':
            element = (
                <FiltersControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'APPLIED_FILTER':
            element = (
                <FiltersApplied
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'ALUMNI_GRID_WITH_CARDS':
            element = (
                <AlumniGridWithCardsControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'TOP_FILTER_PILLS':
            element = (
                <TopFilterPillsControl
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'GRID_CARD':
            element = (
                <GridCardsControl
                    key={childDataKey}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'NOTIFY_ME_CARD':
            element = (
                <NotifyMeCardsControl
                    key={childDataKey}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'TABLE':
            element = <TableControl control={control} dataKey={dataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />;
            break;
        case 'SELECT_V3':
            element = (
                <SelectControlV3 control={control} dataKey={childDataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />
            );
            break;
        case 'SELECT_V4':
                element = (
                <SelectControlV4 control={control} dataKey={childDataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />
            );
            break;
        case 'SELECT_EDITABLE':
            element = (
                <SelectEditable control={control} dataKey={childDataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />
            );
            break;
        case 'SELECT_EDITABLE_ONCHANGE':
            element = (
                <SelectEditableOnchange control={control} dataKey={childDataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />
            );
            break;
        case 'SELECT_EDITABLE_MULTI':
            element = (
                <SelectEditableMulti control={control} dataKey={childDataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />
            );
            break;
        case 'PHOTO_ALBUM_CONTROL':
            element = (
                <AlbumPhotoUploderControl
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
        case 'ACTION_ICON_GROUP':
            element = (
                <ActionIconGroup control={control} dataKey={childDataKey} parentDataKey={dataKey} handleChangeEvent={handleChangeEvent} />
            );
            break;
        
        case 'SMART_FORM_GRID':
            element = (
                <SmartFormGrid
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;

        case 'SMART_FILE_UPLOADER':
            element = (
                <SmartFileUploader
                    key={keyVal}
                    control={control}
                    dataKey={childDataKey}
                    parentDataKey={dataKey}
                    handleChangeEvent={handleChangeEvent}
                />
            );
            break;
            
        default:
            element = <ChildWrapperControl type={control.type} control={control} dataKey={childDataKey} parentDataKey={dataKey} />;
    }

    // if (isHidden) return <div key={keyVal}></div>; //@by RG 
    // @note: eventhough the element is hidden, Empty space is displaying in grid, updated inoder to make the form grid adjust as per the visibe items;
    if (isHidden) return <div style={{display: 'none'}} key={keyVal}></div>;

    const getComponentStyle = () => {
        if (customStyle) return customStyle;
        return isEmpty(control?.className) ? ' col-md-12 ' : control?.className;
    };

    return (
        <div key={keyVal} className={`${getComponentStyle()} ${padding}`}>
            {element}
        </div>
    );
};
