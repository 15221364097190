export enum Column {
    CAMPUS = 'CAMPUS',
    CURRICULUM = 'CURRICULUM',
    COURSE = 'COURSE',
    ENGAGEMENTS = 'ENGAGEMENTS',
    LAST_INTERACTION = 'LAST_INTERACTION',
    UNIVERSITY = 'UNIVERSITY',
    PREF_CAREER = 'CAREER',
    PREF_COUNTRY = 'COUNTRY',
    PREF_COLLEGE = 'COLLEGE',
    PRESENT_STATUS = 'PRESENT_STATUS',
    LOCATION = 'LOCATION',
    CREATED_ON = 'CREATED_ON',
    MODIFIED_ON = 'MODIFIED_ON',
    ACTION = 'ACTION',
}

export enum Action {
    ACTIVATE = 'Activate',
    DEACTIVATE = 'Deactivate',
    SEND_MESSAGE = 'Send Message',
    MARK_AS_NOTABLE = 'Mark as Notable',
    UNMARK_AS_NOTABLE = 'Unmark as Notable',
    ADD_NOTES = 'Add Notes',
    VIEW_NOTES = 'View Notes',
    SEND_INVITATION = 'Send Invitation',
    SOCIAL_SHARE = 'Social Share',
    SEND_VIA_EMAIL = 'Send via Email',
    CONVERT_TO_ALUMNI = 'Convert To Alumni',
    EDIT_PROFILE='Edit',
    VERIFY_EMAIL='Verify Email'
}

export const reportConfig: any = {
    'track-student-profiles': {
        gridColumns: [Column.CAMPUS, Column.CURRICULUM, Column.CREATED_ON, Column.MODIFIED_ON, Column.ACTION],
        // actions: [Action.ACTIVATE, Action.DEACTIVATE, Action.SEND_MESSAGE, Action.CONVERT_TO_ALUMNI],
        // actions: [Action.EDIT_PROFILE,Action.ACTIVATE, Action.DEACTIVATE],
        actions: [Action.EDIT_PROFILE,Action.ACTIVATE,Action.VERIFY_EMAIL],
        fuzzySearchPlaceholder: 'Search by Student Name, Email, User ID',
    },
    'track-student-career-preferences': {
        gridColumns: [Column.CAMPUS, Column.CURRICULUM, Column.PREF_CAREER, Column.PREF_COUNTRY, Column.PREF_COLLEGE, Column.ACTION],
        // actions: [Action.ADD_NOTES, Action.SEND_MESSAGE],
        actions: [ Action.SEND_MESSAGE],
        fuzzySearchPlaceholder: 'Search by Student Name',
    },
    'track-alumni-profiles': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.LOCATION, Column.CREATED_ON, Column.ACTION],
        // actions: [Action.EDIT_PROFILE,Action.ACTIVATE, Action.DEACTIVATE, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE, Action.SEND_VIA_EMAIL, Action.SOCIAL_SHARE, Action.UNMARK_AS_NOTABLE],
        actions: [Action.EDIT_PROFILE,Action.ACTIVATE,Action.VERIFY_EMAIL, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE, Action.SEND_VIA_EMAIL, Action.SOCIAL_SHARE, Action.UNMARK_AS_NOTABLE],
        fuzzySearchPlaceholder: 'Search by Alumni Name',
    },
    'track-alumni-education-preferences': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.COURSE, Column.UNIVERSITY, Column.ACTION],
        // actions: [Action.ACTIVATE, Action.DEACTIVATE, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE, Action.UNMARK_AS_NOTABLE],
        actions: [Action.ACTIVATE, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE, Action.UNMARK_AS_NOTABLE],
        fuzzySearchPlaceholder: 'Search by Alumni Name',
    },
    'track-alumni-engagement-with-students': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.ENGAGEMENTS, Column.LAST_INTERACTION, Column.ACTION],
        // actions: [Action.ACTIVATE, Action.DEACTIVATE, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE, Action.UNMARK_AS_NOTABLE],
        actions: [Action.ACTIVATE, Action.SEND_MESSAGE, Action.MARK_AS_NOTABLE, Action.UNMARK_AS_NOTABLE],
        fuzzySearchPlaceholder: 'Search by Alumni Name',
    },
    'track-alumni-highlights-with-students-engagement': {
        gridColumns: [Column.CAMPUS, Column.PRESENT_STATUS, Column.ENGAGEMENTS, Column.LAST_INTERACTION, Column.ACTION],
        actions: [Action.SEND_MESSAGE],
    },
};
