import StandardLayout from '../app/alumni/StandardLayout';
import AlbumListing from '../features/album/listing/AlbumListing';
import AlbumListingService from '../features/album/listing/AlbumListingService';
import CreateAlbum from '../features/album/posting/CreateAlbum';
import AlumniDirectory from '../features/alumni/alumni-directory/AlumniDirectory';
import AlumniDirectoryService from '../features/alumni/alumni-directory/AlumniDirectoryService';
import AlumniProfile from '../features/alumni/alumni-profile/AlumniProfile';
import OfficeUsage from '../features/alumni/alumni-profile/sections/OfficeUsage';
import ProfileMarker from '../features/alumni/alumni-profile/sections/admin/ProfileMarker/ProfileMarker';
import ProfileAchievements from '../features/alumni/alumni-profile/sections/ProfileAchievements';
import ProfileBenefits from '../features/alumni/alumni-profile/sections/ProfileBenefits';
import ProfileConsent from '../features/alumni/alumni-profile/sections/ProfileConsent';
import ProfileContributions from '../features/alumni/alumni-profile/sections/ProfileContributions';
import ProfilePostGraduate from '../features/alumni/alumni-profile/sections/ProfilePostGraduate';
import ProfilePreferences from '../features/alumni/alumni-profile/sections/ProfilePreferences';
import ProfileSkills from '../features/alumni/alumni-profile/sections/ProfileSkills';
import ProfileUnderGraduate from '../features/alumni/alumni-profile/sections/ProfileUnderGraduate';
import ProfileWorkExperience from '../features/alumni/alumni-profile/sections/ProfileWorkExperience';
import UserInteraction from '../features/alumni/alumni-profile/sections/UserInteraction';
import ProfileInformation from '../features/alumni/alumni-profile/sections/admin/ProfileInformation';
import Dashboard from '../features/alumni/dashboard/Dashboard';
import DashboardService from '../features/alumni/dashboard/DashboardPageLoader';
import AlumniVerify from '../features/alumni/onboarding/AlumniVerify';
import Onboarding from '../features/alumni/onboarding/Onboarding';
import Welcome from '../features/alumni/onboarding/Welcome';
import Achievement from '../features/alumni/profile-edit/achievements/Achievement';
import AlumniBreak from '../features/alumni/profile-edit/alumni-break/AlumniBreak';
import AlumniSkill from '../features/alumni/profile-edit/alumni-skills/AlumniSkill';
import AlumniBasicInfo from '../features/alumni/profile-edit/basic-info/AlumniBasicInfo';
import AlumniProfileImage from '../features/alumni/profile-edit/basic-info/AlumniProfileImage';
import Benefits from '../features/alumni/profile-edit/benefits/Benefits';
import EntranceTest from '../features/alumni/profile-edit/entrance-test/EntranceTest';
import Interest from '../features/alumni/profile-edit/interest/Interest';
import JobPreferences from '../features/alumni/profile-edit/job-preferences/JobPreferences';
import Outcome from '../features/alumni/profile-edit/outcome/Outcome';
import PgEducationPreference from '../features/alumni/profile-edit/pg-education-preference/PgEducationPreference';
import PostGraduate from '../features/alumni/profile-edit/post-graduate/PostGraduate';
import ProfessionalWork from '../features/alumni/profile-edit/professional-work/ProfessionalWork';
import Scholarship from '../features/alumni/profile-edit/scholarship/Scholarship';
import StepOneCompletion from '../features/alumni/profile-edit/step-1-completion/StepOneCompletion';
import StepTwoCompletion from '../features/alumni/profile-edit/step-2-completion/StepTwoCompletion';
import StudentPreference from '../features/alumni/profile-edit/student-preference/StudentPreference';
import UgEducationPreference from '../features/alumni/profile-edit/ug-education-preference/UgEducationPreference';
import UnderGraduate from '../features/alumni/profile-edit/under-graduate/UnderGraduate';
import Entrepreneurship from '../features/alumni/profile-edit/entrepreneurship/Entrepreneurship';
import UserSchool from '../features/alumni/profile-edit/user-school/UserSchool';
import GuidanceListing from '../features/guidance/listing/GuidanceListing';
import GuidanceService from '../features/guidance/listing/GuidanceService';
import JobPosting from '../features/jobs/job-posting/JobPosting';
import JobListing from '../features/jobs/listing/JobListing';
import JobListingService from '../features/jobs/listing/JobListingService';
import EventsListing from '../features/meetings-events/listing/EventsListing';
import EventsService from '../features/meetings-events/listing/EventsService';
import Messages from '../features/messages/Messages';
import MessagesService from '../features/messages-module/MessageApiService';
import StudentActivity from '../features/student/edit/activity/StudentActivity';
import StudentBooks from '../features/student/edit/books/StudentBooks';
import Education from '../features/student/edit/education/Education';
import EntranceExam from '../features/student/edit/entrance-exams/EntranceExam';
import Parents from '../features/student/edit/parents/Parents';
import EducationDetails from '../features/student/view/sections/EducationDetails';
import EntranceExams from '../features/student/view/sections/EntranceExams';
import ExtraCurricular from '../features/student/view/sections/ExtraCurricular';
import ParentInformation from '../features/student/view/sections/ParentInformation';
import Preferences from '../features/student/view/sections/Preferences';
import pageLoader from '../library/Core/PageLoader';
import ErrorPage from '../library/SimpleControls/ErrorPage';
import StudentService from '../services/student.service';
import JobOutcome from '../features/alumni/profile-edit/job-outcome/JobOutcome';
import GuidanceVideos from '../features/alumni/alumni-profile/sections/admin/guidance-videos/GuidanceVideos';
import MessagesModule from '../features/messages-module/Messages';
import AdminInstituteSelection from '../features/auth/admin-institute-selection/AdminInstituteSelection';
import AskQuestion from '../features/qna/AskQuestion';
import AnswerQuestion from '../features/qna/AnswerQuestion';
import ViewMoreQuestion from '../features/qna/ViewMoreQuestion';
import StudentDashboard from '../features/qna/StudentDashboard';
import QnaApiService from '../features/qna/QnaApiService';
import OfficeMarker from '../features/alumni/alumni-profile/sections/OfficeMarker';
import ManageEventsService from '../features/meetings-events/add/ManageEventsService';
import ManageEvents from '../features/meetings-events/add/ManageEvents';
import TeachingJob from '../features/alumni/profile-edit/teaching-job/TeachingJob';
import ProfessionalPractice from '../features/alumni/profile-edit/professional-practice/ProfessionalPractice';

export const alumniRoutes = {
    path: '/',
    element: <StandardLayout />,
    errorElement: <ErrorPage />,
    children: [
        {
            path: 'alumni',
            children: [
                {
                    path: 'onboarding',
                    loader: async ({ params }) => {
                        document.title = 'Getting Started';
                        return pageLoader({ ...params, pageName: 'alumni-onboarding' });
                    },
                    element: <Onboarding />,
                },
                {
                    path: 'welcome',
                    loader: async ({ params }) => {
                        document.title = 'Validate your alumni status';
                        return pageLoader({ ...params, pageName: 'alumni-welcome' });
                    },
                    element: <Welcome />,
                },
                {
                    path: 'dashboard',
                    loader: async ({ params }) => {
                        document.title = 'View Dashboard';
                        return DashboardService.pageLoader({ ...params, pageName: 'alumni-dashboard' });
                    },
                    element: <Dashboard />,
                },
                {
                    path: 'directory/:selectedCard?',
                    loader: async ({ params }) => {
                        document.title = 'Alumni Directory';
                        return AlumniDirectoryService.pageLoader({ ...params, pageName: 'alumni-directory', searchBy: 'default' });
                    },
                    element: <AlumniDirectory key="directory" />,
                },
                {
                    path: 'batch-mates-directory',
                    loader: async ({ params }) => {
                        document.title = 'Alumni Directory';
                        return AlumniDirectoryService.pageLoader({ ...params, pageName: 'alumni-directory', searchBy: 'BATCH_MATES' });
                    },
                    element: <AlumniDirectory key="batch-mates-directory" />,
                },
                {
                    path: 'juniors-directory',
                    loader: async ({ params }) => {
                        document.title = 'Alumni Directory';
                        return AlumniDirectoryService.pageLoader({ ...params, pageName: 'alumni-directory', searchBy: 'JUNIORS' });
                    },
                    element: <AlumniDirectory key="juniors-directory" />,
                },
                {
                    path: 'seniors-directory',
                    loader: async ({ params }) => {
                        document.title = 'Alumni Directory';
                        return AlumniDirectoryService.pageLoader({ ...params, pageName: 'alumni-directory', searchBy: 'SENIORS' });
                    },
                    element: <AlumniDirectory />,
                },
                {
                    path: 'school-directory',
                    loader: async ({ params }) => {
                        document.title = 'School Connect';
                        return AlumniDirectoryService.pageLoader({ ...params, pageName: 'alumni-directory', searchBy: 'SCHOOL' });
                    },
                    element: <AlumniDirectory />,
                },
                {
                    path: ':uiid1/messages',
                    loader: async ({ params }) => {
                        document.title = 'Messages';
                        return MessagesService.pageLoader({ ...params, pageName: 'messages', searchBy: '' });
                    },
                    element: <MessagesModule />,
                },
                {
                    path: ':id',
                    children: [
                        {
                            path: 'profile',
                            element: <AlumniProfile key="ALUMNI_PROFILE" />,
                            loader: async ({ params }) => {
                                document.title = 'Alumni Profile';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'student-profile',
                                    includeAdditionalInfo: true,
                                    includeComputedFieldsJson: true,
                                });
                            },
                            children: [
                                { path: '', element: <ProfileUnderGraduate /> },
                                // { path: 'office-usage', element: <OfficeUsage /> },
                                { path: 'office-usage', element: <OfficeMarker /> },
                                { path: 'profile-marker', element: <ProfileMarker /> },
                                { path: 'profile-information', element: <ProfileInformation /> },
                                { path: 'ug', element: <ProfileUnderGraduate /> },
                                { path: 'pg', element: <ProfilePostGraduate /> },
                                { path: 'work', element: <ProfileWorkExperience /> },
                                { path: 'mentoring-interests', element: <ProfileConsent /> },
                                { path: 'achievements', element: <ProfileAchievements /> },
                                { path: 'contributions', element: <ProfileContributions /> },
                                { path: 'skills', element: <ProfileSkills /> },
                                { path: 'preferences', element: <ProfilePreferences /> },
                                { path: 'interaction', element: <UserInteraction /> },
                                { path: 'benefits', element: <ProfileBenefits /> },

                                { path: 'preference', element: <Preferences /> },
                                { path: 'entrance-exams', element: <EntranceExams /> },
                                { path: 'education', element: <EducationDetails /> },
                                { path: 'parent-info', element: <ParentInformation /> },
                                { path: 'job-preferences', element: <ProfilePreferences /> },
                                { path: 'extra-curricular', element: <ExtraCurricular /> },
                                { path: 'guidance-videos', element: <GuidanceVideos /> },
                            ],
                        },
                        {
                            path: 'alumni-verify',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-verify',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <AlumniVerify />,
                        },
                        {
                            path: 'info',
                            loader: async ({ params }) => {
                                document.title = 'Update Basic Information';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-basic-info',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <AlumniBasicInfo />,
                        },
                        {
                            path: 'ug',
                            loader: async ({ params }) => {
                                document.title = 'Update Education Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-undergraduate',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <UnderGraduate />,
                        },
                        {
                            path: 'entrepreneurship',
                            loader: async ({ params }) => {
                                document.title = 'Entrepreneurship Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-entrepreneurship',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <Entrepreneurship />,
                        },
                        {
                            path: 'teaching-experience',
                            loader: async ({ params }) => {
                                document.title = 'Teaching Work';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-teaching-work',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <TeachingJob />,
                        },
                        {
                            path: 'professional-practice-experience',
                            loader: async ({ params }) => {
                                document.title = 'Professional Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-professional-practice',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <ProfessionalPractice />,
                        },
                        {
                            path: 'job-outcome',
                            loader: async ({ params }) => {
                                document.title = 'Work Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-outcome-work',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <JobOutcome />,
                        },
                        {
                            path: 'pg',
                            loader: async ({ params }) => {
                                document.title = 'Update Education Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-pg-details',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <PostGraduate />,
                        },
                        {
                            path: 'scholarship',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-scholarship',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <Scholarship />,
                        },
                        {
                            path: 'entrance-test',
                            loader: async ({ params }) => {
                                document.title = 'Update Exam Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-entrance-test',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <EntranceTest />,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'work',
                            loader: async ({ params }) => {
                                document.title = 'Update Work Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-professional-work',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <ProfessionalWork />,
                        },
                        {
                            path: 'ug-education-preference',
                            loader: async ({ params }) => {
                                document.title = 'Update Study Preferences';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-ug-education-preferences',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <UgEducationPreference />,
                        },
                        {
                            path: 'pg-education-preference',
                            loader: async ({ params }) => {
                                document.title = 'Update Study Preferences';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-pg-education-preferences',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <PgEducationPreference />,
                        },
                        {
                            path: 'job-preferences',
                            loader: async ({ params }) => {
                                document.title = 'Update Job Preferences';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-job-preferences',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <JobPreferences />,
                        },
                        {
                            path: 'achievements',
                            loader: async ({ params }) => {
                                document.title = 'Add Achievements';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-achievement',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <Achievement />,
                        },
                        {
                            path: 'skills',
                            loader: async ({ params }) => {
                                document.title = 'Update Skill Profile';
                                return StudentService.pageLoader({ ...params, pageName: 'alumni-skills', includeAdditionalInfo: true });
                            },
                            element: <AlumniSkill />,
                        },
                        {
                            path: 'mentoring-interests',
                            loader: async ({ params }) => {
                                document.title = 'Mentoring Interests';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-mentoring-interests',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <Interest />,
                        },
                        {
                            path: 'profile-picture',
                            loader: async ({ params }) => {
                                document.title = 'Update Profile Picture';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-profile-picture',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <AlumniProfileImage />,
                        },
                        {
                            path: 'benefits',
                            loader: async ({ params }) => {
                                document.title = 'Get Benefits';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-benefits',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <Benefits />,
                        },
                        {
                            path: 'step-one',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({ ...params, pageName: null, includeAdditionalInfo: true });
                            },
                            element: <StepOneCompletion />,
                        },
                        {
                            path: 'step-two',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({ ...params, pageName: null, includeAdditionalInfo: true });
                            },
                            element: <StepTwoCompletion />,
                        },
                        {
                            path: 'student-took-a-break',
                            loader: async ({ params }) => {
                                document.title = 'Update Profile';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'student-took-a-break',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <AlumniBreak />,
                        },
                        {
                            path: 'student-school',
                            loader: async ({ params }) => {
                                document.title = 'Update Schooling Details';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'student-school-outcomes',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <UserSchool />,
                        },
                        {
                            path: 'select-institute',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'admin-select-instiitute',
                                    includeAdditionalInfo: false,
                                });
                            },
                            errorElement: <ErrorPage />,
                            element: <AdminInstituteSelection pageTitle="Choose Institute" />,
                        },
                        {
                            path: 'outcome',
                            loader: async ({ params }) => {
                                document.title = 'Update Program Outcome';
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'alumni-program-outcomes',
                                    includeAdditionalInfo: false,
                                });
                            },
                            element: <Outcome />,
                        },
                        {
                            path: 'student-preference',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'student-education-preferences',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <StudentPreference />,
                        },
                        {
                            path: 'parent-info',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({ ...params, pageName: 'student-parents', includeAdditionalInfo: true });
                            },
                            element: <Parents />,
                        },
                        {
                            path: 'education',
                            loader: async ({ params }) => {
                                document.title = 'Update Education Details';
                                return StudentService.pageLoader({ ...params, pageName: 'student-education' });
                            },
                            element: <Education />,
                        },
                        {
                            path: 'entrance-exam',
                            loader: async ({ params }) => {
                                document.title = 'Update Exam Details';
                                return StudentService.pageLoader({ ...params, pageName: 'student-entrance-exams' });
                            },
                            element: <EntranceExam />,
                        },
                        {
                            path: 'books',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({
                                    ...params,
                                    pageName: 'student-reading-interest',
                                    includeAdditionalInfo: true,
                                });
                            },
                            element: <StudentBooks />,
                        },
                        {
                            path: 'activity',
                            loader: async ({ params }) => {
                                return StudentService.pageLoader({ ...params, pageName: 'student-activity', includeAdditionalInfo: true });
                            },
                            element: <StudentActivity />,
                        },
                    ],
                },
            ],
        },
        {
            path: 'messages-old',
            loader: async ({ params }) => {
                document.title = 'Messages';
                return MessagesService.pageLoader({ ...params, pageName: 'messages', searchBy: '' });
            },
            element: <Messages />,
        },
        {
            path: 'student-messages',
            loader: async ({ params }) => {
                document.title = 'Messages';
                return MessagesService.pageLoader({ ...params, pageName: 'messages', searchBy: 'STUDENT' });
            },
            element: <Messages key="STUDENT" />,
        },
        {
            path: 'alumni-messages',
            loader: async ({ params }) => {
                document.title = 'Messages';
                return MessagesService.pageLoader({ ...params, pageName: 'messages', searchBy: 'ALUMNI' });
            },
            element: <Messages key={'ALUMNI'} />,
        },
        {
            path: 'messages-old/:action/:ids',
            loader: async ({ params }) => {
                const ids = params.ids.split(',');
                return MessagesService.pageLoader({ ...params, ids, pageName: 'messages', newMessageMode: true });
            },
            element: <Messages />,
        },
        {
            path: 'job-posting',
            children: [
                {
                    path: '',
                    loader: async ({ params }) => {
                        document.title = 'Jobs & Internships';
                        return JobListingService.pageLoader({ ...params, pageName: 'alumni-job-listing', searchBy: 'ACTIVE_LIST' });
                    },
                    element: <JobListing />,
                },
                {
                    path: ':id',
                    loader: async ({ params }) => {
                        document.title = 'Post a Job';
                        return pageLoader({ ...params, pageName: 'alumni-job-posting' });
                    },
                    element: <JobPosting />,
                },
            ],
        },
        {
            path: 'memories',
            children: [
                {
                    path: '',
                    loader: async ({ params }) => {
                        document.title = 'Memories';
                        return AlbumListingService.pageLoader({ ...params, pageName: 'alumni-album', searchBy: 'default' });
                    },
                    element: <AlbumListing />,
                },
                {
                    path: ':id',
                    loader: async ({ params }) => {
                        document.title = 'Create Album';
                        return pageLoader({ ...params, pageName: 'alumni-album' });
                    },
                    element: <CreateAlbum />,
                },
            ],
        },
        {
            path: 'guidance',
            children: [
                {
                    path: '',
                    loader: async ({ params }) => {
                        document.title = 'Guidance Videos';
                        return GuidanceService.pageLoader({ ...params, pageName: 'alumni-guidance', searchBy: 'default' });
                    },
                    element: <GuidanceListing />,
                },
            ],
        },
        {
            path: 'events',
            children: [
                {
                    path: 'add',
                    loader: async ({ params }) => {
                        document.title = 'Create Event';
                        return ManageEventsService.pageLoader({ ...params, pageName: 'event-add' });
                    },
                    element: <ManageEvents />,
                },
                {
                    path: ':searchBy',
                    loader: async ({ params }) => {
                        document.title = params.searchBy === 'upcoming' ? 'Upcoming Events' : 'Past Events';
                        return EventsService.pageLoader({ ...params, pageName: 'meetings_events' });
                    },
                    element: <EventsListing />,
                },
            ],
        },
        {
            path: 'messages/:uiid1?/:uiid2?/:uiid3?',
            loader: async ({ params }) => {
                document.title = 'Messages';
                return MessagesService.pageLoader({ ...params, pageName: 'messages', searchBy: '' });
            },
            element: <MessagesModule />,
        },
        {
            path: 'ask-question',
            loader: async ({ params }) => {
                document.title = 'QNA';
                return QnaApiService.pageLoader({ ...params, pageName: 'qna', searchBy: '' });
            },
            element: <AskQuestion />,
        },
        {
            path: 'qna-dashboard',
            loader: async ({ params }) => {
                document.title = 'Student QNA';
                return QnaApiService.pageLoader({ ...params, pageName: 'qna', searchBy: '' });
            },
            element: <StudentDashboard />,
        },
        {
            path: 'answer-question',
            loader: async ({ params }) => {
                document.title = 'QNA';
                return QnaApiService.pageLoader({ ...params, pageName: 'qna', searchBy: '' });
            },
            element: <AnswerQuestion />,
        },
        {
            path: 'view-more-question',
            loader: async ({ params }) => {
                document.title = 'QNA';
                return QnaApiService.pageLoader({ ...params, pageName: 'qna', searchBy: '' });
            },
            element: <ViewMoreQuestion />,
        },
    ],
};
