import { getDomainValueForCode, isEmpty, isFormValid, isValidImageFile, setError, triggerGridDataOnChange, isValidVideoUrl } from '../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import { axiosClient } from '../../library/Service/axiosClient';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';
import { redirect } from 'react-router-dom';

export const handleSave = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const code = state?.internal?.widget_code;
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if ( (!customValidationPassed)) {
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    let saveUrl = `${process.env.REACT_APP_WIDGET_API_SERVER}/action/save`;
    let redirectUrlSlug = 'configuration';

    switch (code) {
        case 'top_careers':
        case 'top_study_country':
        case 'top_companies':
        case 'top_colleges':
        case 'directory_stats':
            redirectUrlSlug = 'configure';
            break;
    }
    
    axiosClient()
        .post(`${saveUrl}`, {
            widget_code: code,
            data: JSON.stringify(state['data']),
            // data: JSON.stringify({...state['data'], widgetData: widgetData}),
            widget_institute_id: state['routeInfo']['id']
        })
        .then((res: any) => {
            const responseUserData = res.data as any;
            if (responseUserData.success == 0) {
                Swal.fire(responseUserData.message);
                return false;
            } else {
                dispatch({ type: 'ROUTE_INFO', payload: { ...state.routeInfo, id: responseUserData.id } });

                Swal.fire(responseUserData.message)
                setTimeout(() => {
                    state.actions['REDIRECT'](`/widget/${redirectUrlSlug}/${state?.internal?.widget_code}/${responseUserData.id}`);
                }, 1000)
            }
        })
        .catch((err: any) => {
            console.error(err);
            alert('Oops..! Something went wrong, Please try again in a while..!!');
        });
};

export const handlePreview = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${state?.routeInfo?.id}`;
    window.open(url, '_blank');
};

export const handleReset = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const widget_code =state.internal.widget_code;
    const metricData = state?.data?.metricData;
    let ctr = 0;

    if (!Array.isArray(metricData)) {
        console.error('metricData is not an array', metricData);
        return;
    }
    const updatedMetricData = metricData.map(item => ({ ...item }));
    // state.internal.top_careers.forEach((rec: any, idx: any) => {
    //     if (rec?.metricEntityCode == 0 || ctr >= 4) return;
    //     if (updatedMetricData[ctr]) {
    //         updatedMetricData[ctr]['metricType'] = rec?.metricEntityCode;
    //         ctr++;
    //     }
    // });

    let metricTypeKey = 'metricType';
    let metricEntityKey = 'metricEntity';
    let defaultMetricTypeValue: any = 'actual';
    
    switch (widget_code) {
        case 'directory_stats':
            metricTypeKey = 'statMetricType';
            metricEntityKey = 'statMetricEntity';
            defaultMetricTypeValue = 1;
            break;
    }

    state.data.metricData.forEach((rec: any, idx: any) => {
        if (rec?.metricEntityCode == 0) return;
        if (updatedMetricData[ctr]) {
            // updatedMetricData[ctr]['metricType'] = 'actual';
            updatedMetricData[ctr][metricTypeKey] = defaultMetricTypeValue;
            ctr++;
        }
    });

    dispatch({
        type: 'REFRESH_DATA',
        payload: {
            data: {...state.data, metricData: updatedMetricData}
        },
    });

    setTimeout(() => {
        // triggerGridDataOnChange(updatedMetricData, "metricData", "metricEntity");
        triggerGridDataOnChange(updatedMetricData, "metricData", metricEntityKey);
    }, 1000);
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    const widgetData    = state?.data?.widgetData;
    const metricData    = state?.data?.metricData;
    const code          = state?.internal?.widget_code;
    let isFormInvalid   = false;
    const metricEntityIds       = new Set();
    let minRows = 0;
    let metricEntityName = '';
    let metricEntityKey  = 'metricEntity';
    let valueKey         = 'value';
    let largeHeadingRequired = false;
    let mediumHeadingRequired = false;
    let headingRequired = false;
    let percentageValue = false;
    let requiredDataConfig = {};

    const indexedMetricData = metricData?.map((rec: any, idx: number) => ({ ...rec, originalIndex: idx }));

    switch (code) {
        case 'top_careers':
            minRows = 4;
            metricEntityName = 'career';
            headingRequired = true;
            percentageValue = true;
            break;

        case 'top_study_country':
        case 'current_location_country':
        case 'top_industries':
            minRows = 4;
            metricEntityName = 'country';
            headingRequired = true;
            percentageValue = true;
            break;

        case 'top_companies':
            minRows = 1;
            metricEntityName = 'industry';
            metricEntityKey  = 'metricEntity2';
            valueKey         = 'cnt';
            largeHeadingRequired    = true;
            mediumHeadingRequired   = true;
            break;
    
        case 'top_colleges':
            minRows = 1;
            metricEntityName = 'country';
            metricEntityKey  = 'metricEntity2';
            valueKey         = 'cnt';
            largeHeadingRequired    = true;
            mediumHeadingRequired   = true;
            break;

        case 'directory_stats':
            minRows = 1;
            metricEntityName = 'metric';
            metricEntityKey  = 'statMetricEntity';
            // valueKey         = 'displayValue';
            largeHeadingRequired    = true;
            mediumHeadingRequired   = true;
            break;

        case 'alumni_occupation':
            headingRequired = true;
            // percentageValue = true;
            break;

        case 'appeal_video':
            if ( isEmpty(widgetData?.thumbnail) ) {
                isFormInvalid = true;
                setError(`widgetData.thumbnail`, [`Please upload "Display Image"`], dispatch);
            } else {
                // Validate if the file is an image
                const fileUrl = widgetData?.thumbnail;
                if (!isValidImageFile(fileUrl)) {
                    isFormInvalid = true;
                    setError('widgetData.thumbnail', ['Please upload a valid image file'], dispatch);
                }
            }

            if (!isEmpty(widgetData?.videoUrl)) {
                // Regex patterns for YouTube and Vimeo
                const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.+?\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?$/;
                const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)((channels\/[\w]+\/)|(groups\/[\w]+\/)|(album\/\d+\/)?(video\/)?|)(\d+)(?:[?&].*)?$/;

                if (youtubeRegex.test(widgetData?.videoUrl) || vimeoRegex.test(widgetData?.videoUrl)) {
                    setError(`widgetData.videoUrl`, [], dispatch);
                } else {
                    setError(`widgetData.videoUrl`, ['Invalid YouTube or Vimeo URL'], dispatch);
                    isFormInvalid = true;
                }
            }

            isFormInvalid  = isFormInvalid || !isFormValid(state, dispatch);
            break;

        case 'cover_banner':
            const indexedBannerData = state?.data?.bannerData?.map((rec: any, idx: number) => ({ ...rec, originalIndex: idx }));
            indexedBannerData
            ?.forEach((rec: any) => {
                if ( isEmpty (rec?.coverImage)) {
                    setError(`bannerData.${rec.originalIndex}.coverImage`, [`Please upload "Display Image"`], dispatch);
                    isFormInvalid = true;
                } else {
                    // Validate if the file is an image
                    const fileUrl = rec?.coverImage;
                    if (!isValidImageFile(fileUrl)) {
                        isFormInvalid = true;
                        setError(`bannerData.${rec.originalIndex}.coverImage`, [`Please upload a valid image file`], dispatch);
                    }
                }

                if ( isEmpty (rec?.largeHeading)) {
                    setError(`bannerData.${rec.originalIndex}.largeHeading`, [`Please enter "Large Heading"`], dispatch);
                    isFormInvalid = true;
                }

                if ( isEmpty (rec?.mediumHeading)) {
                    setError(`bannerData.${rec.originalIndex}.mediumHeading`, [`Please enter "Medium Heading"`], dispatch);
                    isFormInvalid = true;
                }

                if ( !isEmpty (rec?.introLink) && !isValidVideoUrl(rec?.introLink)) {
                    setError(`bannerData.${rec.originalIndex}.introLink`, [`Please enter a valid youtube or vimeo video url`], dispatch);
                    isFormInvalid = true;
                }
            })
            return !isFormInvalid;
            break;
        
        case 'enquiry_form':
            if ( isEmpty(widgetData?.openBtnText) ) {
                isFormInvalid = true;
                setError(`widgetData.openBtnText`, [`Please enter "Open Button Text"`], dispatch);
            }

            if ( isEmpty(widgetData?.openButtonAlignment) ) {
                isFormInvalid = true;
                setError(`widgetData.openButtonAlignment`, [`Please Select "Open Button Alignment"`], dispatch);
            }
            
            const formConfig = state.data?.formConfig;

            if ( isEmpty(formConfig?.formTitle) ) {
                isFormInvalid = true;
                setError(`formConfig.formTitle`, [`Please enter "Form Title"`], dispatch);
            }

            if ( formConfig?.programsInterestedIn == true && isEmpty(formConfig?.displayPrograms) ) {
                isFormInvalid = true;
                // setError(`formConfig.addMoreFlag`, [`Please select all applicable programs`], dispatch);
                Swal.fire("Please add/select all applicable programs..!");
            }

            // if ( formConfig?.addMoreFlag == true ) {
            //     formConfig.customPrograms?.map((item: any, idx: any) => {
            //         if ( item?.program?.trim() == "" ) {
            //             isFormInvalid = true;
            //             setError(`formConfig.customPrograms.${idx}`, [`Please enter program name`], dispatch);
            //         }
            //     })
            // }
            return !isFormInvalid;
            break;
        
        case 'admission_referral_form':
            if ( isEmpty(widgetData?.openBtnText) ) {
                isFormInvalid = true;
                setError(`widgetData.openBtnText`, [`Please enter "Open Button Text"`], dispatch);
            }
            
            if ( isEmpty(widgetData?.openButtonAlignment) ) {
                isFormInvalid = true;
                setError(`widgetData.openButtonAlignment`, [`Please Select "Open Button Alignment"`], dispatch);
            }
            
            const referralFormConfig = state.data?.formConfig;

            if ( isEmpty(referralFormConfig?.formTitle) ) {
                isFormInvalid = true;
                setError(`formConfig.formTitle`, [`Please enter "Form Title"`], dispatch);
            }

            return !isFormInvalid;
    }
    
    indexedMetricData
        // .filter((rec: any) => rec[metricEntityKey] || rec[valueKey])
        ?.forEach((rec: any) => {
            if ( isEmpty(rec[metricEntityKey]) ) {                
                setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [`Please select ${metricEntityName}!`], dispatch);
                isFormInvalid = true;
                return;
            }

            if (metricEntityIds.has(rec[metricEntityKey])) {
                setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [`Duplicates are not allowed!`], dispatch);
                isFormInvalid = true;
            } else {
                metricEntityIds.add(rec[metricEntityKey]);
            }

            // if ( ['top_careers', 'top_study_country', 'current_location_country', 'top_industries'].includes(code) ) {
            if( percentageValue ) {
                if ( isEmpty(rec.value) ) {
                    setError(`metricData.${rec.originalIndex}.value`, [`Please fill value..!`], dispatch);
                    isFormInvalid = true;
                }

                if ( !isEmpty(rec.value) && (parseInt(rec.value) <= 0 || parseInt(rec.value) > 100)) {
                    setError(`metricData.${rec.originalIndex}.value`, [`Value must be 1-100`], dispatch);
                    isFormInvalid = true;
                }
            }

            if ( ['directory_stats'].includes(code) ) {
                if ( isEmpty(rec?.displayValue) ) {
                    setError(`metricData.${rec.originalIndex}.displayValue`, [`Please fill value..!`], dispatch);
                    isFormInvalid = true;
                } else {
                    if (rec?.statMetricType == 1) {
                        setError(`metricData.${rec.originalIndex}.displayValue`, [], dispatch);
                    }
                }
            }

            if ( ['top_companies'].includes(code) ) {
                if ( !isEmpty(rec.cnt) && (parseInt(rec.cnt) <= 0)) {
                    let err = "No companies found!";
                    if ( state?.data?.widgetData?.sourceType == 'external_data') {
                        err = 'No company added!';
                    }
                    setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [err], dispatch);
                    isFormInvalid = true;
                }
            }

            if ( ['top_colleges'].includes(code) ) {
                if ( !isEmpty(rec.cnt) && (parseInt(rec.cnt) <= 0)) {
                    let err = "No colleges found!";
                    if ( state?.data?.widgetData?.sourceType == 'external_data') {
                        err = 'No college added!';
                    }
                    setError(`metricData.${rec.originalIndex}.${metricEntityKey}`, [err], dispatch);
                    isFormInvalid = true;
                }
            }
        });

    if ( !isEmpty(widgetData?.other) && (parseInt(widgetData?.other) < 0 || parseInt(widgetData?.other) > 100)) {
        setError(`widgetData.other`, [`Invalid Percentage value`], dispatch);
        isFormInvalid = true;
    }

    if ( ['alumni_occupation'].includes(code) && widgetData.other != 0) {
        errorMessages.push(`Percentages must add up to 100%. Please adjust your entries.`);
        isFormInvalid = true;
    }
    // if ( ['top_careers', 'top_study_country', 'top_companies', 'top_colleges', 'directory_stats'].includes(code) ) {
    if ( minRows > 0) {
        if ( metricEntityIds.size < (minRows) ) {
            errorMessages.push(`At least ${minRows} distinct ${metricEntityName} must be added to continue..!`);
            isFormInvalid = true;
        }
    }
    
    if (headingRequired && isEmpty(widgetData?.heading?.trim())) {
        setError(`widgetData.heading`, [`Please enter "Heading"`], dispatch);
        isFormInvalid = true;
    }

    if (largeHeadingRequired && isEmpty(widgetData?.largeHeading?.trim())) {
        setError(`widgetData.largeHeading`, [`Please enter "Large Heading"`], dispatch);
        isFormInvalid = true;
    }

    if (mediumHeadingRequired && isEmpty(widgetData?.mediumHeading?.trim())) {
        setError(`widgetData.mediumHeading`, [`Please enter "Medium Heading"`], dispatch);
        isFormInvalid = true;
    }
    
    // Dispatch the validation errors to the state
    dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'metricData', errorMessages },
    });

    return !isFormInvalid;
};

export const handleViewList = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const widget_code = state.internal.widget_code;
    let popupId     = '';
    const target    = event.target as HTMLButtonElement;
    const entityId  = target.dataset.val ?? '';

    let entityName;
    let metricName;
    let title;
    let icon;
    let noImg;
    let domainCode;
    let popupData: any = [];

    switch (widget_code) {
        case 'top_companies':
            popupId = 'top_companies_list';
            noImg   = 'briefcase.svg';
            domainCode = 'INDUSTRY_CODE';
            metricName = getDomainValueForCode(entityId, domainCode, state);
            entityName = 'Companies';
            break;

        case 'top_colleges':
            popupId = 'top_colleges_list';
            noImg   = 'no_university_img.svg';
            domainCode = 'COUNTRY_CODE';
            metricName = getDomainValueForCode(entityId, domainCode, state);
            entityName = 'Colleges';
            break;
    }

    if (isEmpty(metricName)) {
        metricName = "Others";
    }

    title   = `${state.internal?.widget_name} with Alumni - ${metricName}`;
    icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`

    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'popupData', value: popupData } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_title', value: title } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_icon', value: icon } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: true } });
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'entityName', value: entityName } });
    handlePopup(popupId, 'list', state);
}

export const handleAddNew = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const widget_code = state.internal.widget_code;
    let popupId     = '';
    const target    = event.target as HTMLButtonElement;
    const entityId  = target.dataset.val ?? '';

    let entityName;
    let title;
    let icon;
    let noImg;
    let domainCode;

    switch (widget_code) {
        case 'top_companies':
            popupId = 'add_company';
            noImg = 'briefcase.svg';
            domainCode = 'INDUSTRY_CODE';
            entityName  = getDomainValueForCode(entityId, domainCode, state);

            if (isEmpty(entityName)) {
                entityName = "Others";
            }

            title   = `Add Company - ${entityName}`;
            icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`
    
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_title', value: title } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_icon', value: icon } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
            break;
        
        case 'top_colleges':
            popupId = 'add_college';
            noImg = 'briefcase.svg';
            domainCode = 'COUNTRY_CODE';
            entityName  = getDomainValueForCode(entityId, domainCode, state);

            if (isEmpty(entityName)) {
                entityName = "Others";
            }

            title   = `Add College - ${entityName}`;
            icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`
    
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_title', value: title } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'widget_icon', value: icon } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
            break;
    }
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isAddModalOpen', value: true } });
    handlePopup(popupId, 'add', state);
}


export const handleUpdate = async (
    entityId: any,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    // event.preventDefault();
    const widget_code = state.internal.widget_code;
    let popupId     = '';
    // const target    = event.target as HTMLButtonElement;
    // const entityId  = target.dataset.val ?? '';

    let entityName;
    let title;
    let icon;
    let noImg;
    let domainCode;

    switch (widget_code) {
        case 'top_companies':
            popupId = 'add_company';
            noImg = 'briefcase.svg';
            domainCode = 'INDUSTRY_CODE';
            entityName  = getDomainValueForCode(entityId, domainCode, state);

            if (isEmpty(entityName)) {
                entityName = "Others";
            }

            title   = `Edit Company`;
            icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`
    
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'edit_widget_title', value: title } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'edit_widget_icon', value: icon } });
            // dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
            break;
        
        case 'top_colleges':
            popupId = 'add_college';
            noImg = 'briefcase.svg';
            domainCode = 'COUNTRY_CODE';
            entityName  = getDomainValueForCode(entityId, domainCode, state);

            if (isEmpty(entityName)) {
                entityName = "Others";
            }

            title   = `Edit College`;
            icon    = `${process.env.REACT_APP_IMAGE_BASEPATH}/${noImg}`
    
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'edit_widget_title', value: title } });
            dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'edit_widget_icon', value: icon } });
            // dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedEntityId', value: entityId } });
            break;
    }
    dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isAddModalOpen', value: true } });
    handlePopup(popupId, 'add', state);
}

const handlePopup = async (popupId: string, type: string, state: State) => {
    try {
        let modalKey = 'isAddModalOpen';
        if ( type === 'list' ) {
            modalKey = 'isListModalOpen';
        }
        const modalElement = document.getElementById(popupId);
        if (modalElement) {
            // if (!state.internal.isModalOpen) {
            if (!state.internal[modalKey]) {
                const modal = new Modal(modalElement);
                modal.show();
            } else {
                const modal = Modal.getInstance(modalElement);
                if (modal) {
                    modal.hide();
                }
            }
        }
    } catch (error) {
        console.error('Error opening modal:', error);
    }
};