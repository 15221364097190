import { useContext, useEffect, useRef } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import WidgetConfigurationLayout from '../WidgetConfigurationLayout';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';

const AdmissionReferralForm = () => {
    const { state, dispatch } = useContext(SmartContext);

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions } });
    }, []);

    // Use a ref to keep track of whether this is the initial render
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            // Skip running the effect on the initial render
            isInitialRender.current = false;
            return;
        }

        let openButtonAlignment = 'center-right';

        if ( state?.data?.widgetData?.openButtonLayout == 'horizontal' ) {
            openButtonAlignment = 'bottom-right';
        }

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    widgetData: {
                        ...state.data.widgetData,
                        openButtonAlignment
                    }
                }
            }
        });

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {...state.data, widgetData: {...state.data.widgetData, openButtonAlignment: openButtonAlignment}}
            }
        });
    }, [state.data.widgetData.openButtonLayout])
    
    return (
        <WidgetConfigurationLayout state={state} >{!state.flags.isDataLoading && <PageBuilder />}</WidgetConfigurationLayout>
    );
};

export default AdmissionReferralForm;