import { ControlOnChangeArguments } from '../../../library/Core/SmartTypes';

import { getDomainWithYearRange } from '../../../library/Service/domainService';
import { axiosClient } from '../../../library/Service/axiosClient';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { useContext } from 'react';
import axios from 'axios';
import { decompressData, setError } from '../../../library/Core/SmartFunctions';

export const schoolNameSelection = async (props: ControlOnChangeArguments) => {
    console.log('props');
    console.log(props.state.domain);
    const schoolId = props.value;
    let response = await axios.get(`${process.env.APP_PAGE_CONFIG_SERVER}/v1/appConfig/domain_data`);
    response = decompressData(response.data);
    return axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/school-directory/mark-notable`, schoolId);
};

//Validation check for length based on country code
export const onMobileNumberChange = (props: ControlOnChangeArguments) => {
    // console.log(props.value);

    const mobileCountryCode = props.row['mobileCountryCode'];
    const mobileNumber = props.value;

    let errorMessage = '';

    if (mobileNumber?.length == 0) {
        errorMessage = 'Please enter mobile number';
        props.errorMessages.push(errorMessage);
        return;
    }

    if (mobileCountryCode != '91') {
        setError('user.mobileNumber', [], props.dispatch);
        return;
    }

    errorMessage = 'Please enter a valid mobile number';

    if ((mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt(props.value.charAt(0)) < 6) && mobileCountryCode == 91) {
        props.errorMessages.push(errorMessage);
    }
};

export const onMobileCountryCodeChange = (props: ControlOnChangeArguments) => {
    // console.log(props.value);

    const mobileCountryCode = props.value;
    const mobileNumber = props.row['mobileNumber'];

    let errorMessage = '';

    if (mobileNumber?.length == 0) {
        errorMessage = 'Please enter mobile number';
        setError('user.mobileNumber', [errorMessage], props.dispatch);

        return;
    }

    if (mobileCountryCode != '91') {
        setError('user.mobileNumber', [], props.dispatch);
        return;
    }

    errorMessage = 'Please enter a valid mobile number';
    // console.log(((mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt((''+mobileNumber).charAt(0)) < 6) && mobileCountryCode == 91),'countryCode condition')
    if (
        (mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt(('' + mobileNumber).charAt(0)) < 6) &&
        mobileCountryCode == 91
    ) {
        setError('user.mobileNumber', [errorMessage], props.dispatch);
    }
};
export const twelthPassoutYear = (props: ControlOnChangeArguments) => {
    const errorMessages = [] as any[];
    // const { globalState } = useContext(GlobalContext);
    props.dispatch({
        type: 'SET_DOMAIN',
        payload: {
            key: 'PAST_YEAR_CODE_10',
            value: getDomainWithYearRange('PAST_YEAR_CODE_10', parseInt(props.value) - 2, parseInt(props.value) - 7),
        },
    });

    if (props.control.id === 'passOutYear') {
        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: 'user.endYear',
                value: parseInt(props.value) - 2,
            },
        });
    }

    props.dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'user.endYear', errorMessages },
    });
};
