import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useImmerReducer } from 'use-immer';
import './App.css';
import { GlobalContext } from './library/Core/GlobalContext';
import globalReducer from './library/Core/GlobalReducer';
import SessionContext from './library/Core/SessionContext';
import sessionReducer from './library/Core/SessionContextReducer';
import { convertDomainArrayToMap, decompressData } from './library/Core/SmartFunctions';
import { GlobalDispatchEvent, GlobalState } from './library/Core/SmartTypes';
import { axiosClient } from './library/Service/axiosClient';
import LoadingSpinner from './library/SimpleControls/LoadingSpinner';
import ProtectedRoute from './routes/ProtectedRoute';
// import Routes from './routes/routes';
import AppSettingsService from './services/app-settings.service';
import router from './router';

function App() {
    // const router = createBrowserRouter(Routes);
    const userSessionExists = sessionStorage.getItem('user-id') == null ? false : true;

    // sessionStorage.setItem('user-jwt-token', localStorage.getItem('user-jwt-token') as string);
    const [sessionState, sessionDispatch] = useImmerReducer(sessionReducer, undefined);

    useEffect(() => {
        function handleStorageEvent(e: any) {
            if (!window.location.href.split('/').includes('login') && e.key === 'user-logged-out' && e.newValue == 'TRUE') {
                console.log('Logging out from this tab...');
                sessionStorage.setItem('user-logged-out', 'TRUE');
                // window.location.href = AppSettingsService.getLoginUrlFromStorage() as string;
                // console.log(AppSettingsService.getLoginUrl(sessionState) as string,'url');
                window.location.href = (`${process.env.REACT_APP_APP_BASE_URL}/` + AppSettingsService.getLoginUrl(sessionState)) as string;
            }
        }

        window.addEventListener('storage', handleStorageEvent);

        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, []);

    const [globalState, globalDispatch] = useImmerReducer<GlobalState, GlobalDispatchEvent>(globalReducer, {
        userNameForSearch: [],
        token: '',
        domain: new Map<string, any>(),
        isAuthenticated: false,
        userData: {},
        schoolInfo: {},
        isLoading: true,
        context: new Map<string, any>(),
    } as GlobalState);

    //Function to check if the user is authenticated
    const isAuthenticated = () => {
        // Replace this logic with your actual authentication check
        // const jwtToken = sessionStorage.getItem('user-jwt-token');
        const jwtToken = localStorage.getItem('user-jwt-token');
        return jwtToken !== null; // Return true if the token is present (user is logged in)
    };

    const userLoggedOut = sessionStorage.getItem('user-logged-out') === 'TRUE' ? true : false;

    // const loginAsUser = async (user: any, schoolDomain: string) => {
    //     const url = window.location.href;
    //     const uuid = url.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/)?.[0];
    //     if (url.includes('/profile') && uuid) {
    //         sessionStorage.setItem('user-jwt-token', user.token);
    //         const impersonatedUserSession = await axiosClient().get(
    //             `${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/impersonate-user?userId=${uuid}`
    //         );
    //         sessionStorage.setItem('is-impersonated-session', 'YES');
    //         storeLoginInfoInSessionStorage(impersonatedUserSession?.data, schoolDomain as string);
    //     }
    // };

    const processSuperAdminLoginScenarios = async (user: any, schoolDomain: string) => {
        const url = window.location.href;
        const uuid = url.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/)?.[0];

        if (!url.includes('/profile')) {
            const localStorageUser = JSON.parse(localStorage.getItem('univariety-user') as string);
            // storeLoginInfoInSessionStorage(localStorageUser, schoolDomain as string);
        } else if (url.includes('/profile') && uuid) {
            sessionStorage.setItem('user-jwt-token', user.token);
            localStorage.setItem('user-jwt-token', user.token);
            //Todo: Need to discuss with Anupama For autologin (show header to admin)
            // const impersonatedUserSession = await axiosClient().post(
            //     `${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/impersonate-user?userId=${uuid}`
            // );
            sessionStorage.setItem('is-impersonated-session', 'YES');
            // storeLoginInfoInSessionStorage(impersonatedUserSession?.data, schoolDomain as string);
        }
    };

    if (!userSessionExists && !userLoggedOut) {
        if (localStorage.getItem('univariety-user') != null) {
            const schoolDomain = localStorage.getItem('user-school-domain');
            const schoolImage = localStorage.getItem('school-image');
            sessionStorage.setItem('school-image', schoolImage as string);
            const localStorageUser = JSON.parse(localStorage.getItem('univariety-user') as string);
            if (localStorageUser?.userTypeCode === 'SUPER_ADMIN') {
                processSuperAdminLoginScenarios(localStorageUser, schoolDomain as string);
            } //else storeLoginInfoInSessionStorage(localStorageUser, schoolDomain as string);
        }
    }

    useEffect(() => {
        const initializeApp = async () => {
            await Promise.all([
                axiosClient().get(`${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/domain_data`),
                // axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/list-for-name-search`),
                axiosClient().get(`${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig`),
            ]).then((values) => {
                const domain = convertDomainArrayToMap(decompressData(values[0]?.data));
                // const userNameForSearch = values[1].data.students;
                const carouselData = values[1].data.find((item: any) => item.data_key === 'CAROUSEL_DATA')?.data_value;
                const benefitsConfig = values[1].data.find((item: any) => item.data_key === 'BENEFITS_CONFIG')?.data_value;
                const activityConfig = values[1].data.find((item: any) => item.data_key === 'ACTIVITY_CONFIG')?.data_value;
                const appSettings = { carouselData, benefitsConfig, activityConfig };
                const sessionContext = {
                    // ...JSON.parse(sessionStorage.getItem('user') as string),
                    // ...JSON.parse(sessionStorage.getItem('user-selected-institute') as string),
                    ...JSON.parse(localStorage.getItem('user') as string),
                    ...JSON.parse(localStorage.getItem('user-selected-institute') as string),
                    instituteLogo: localStorage.getItem('school-image'),
                };

                globalDispatch({
                    type: 'INITIALIZE_APP',
                    payload: { domain, appSettings, isLoading: false, isAuthenticated: isAuthenticated },
                });
                sessionDispatch({ type: 'SET', payload: sessionContext });
            });
        };

        AOS.init();
        initializeApp();
    }, []);

    if (globalState.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <GlobalContext.Provider value={{ globalState, globalDispatch }}>
            {/* <ErrorBoundary> */}
            <SessionContext.Provider value={{ sessionState, sessionDispatch }}>
                <ProtectedRoute>
                    <RouterProvider router={router} />
                </ProtectedRoute>
                <ToastContainer position="bottom-center" autoClose={1000} theme="dark" />
            </SessionContext.Provider>
            {/* </ErrorBoundary> */}
        </GlobalContext.Provider>
    );
}

export default App;
