import { Navigate, useLoaderData } from "react-router-dom";
import { isFormValid } from "../../../library/Core/SmartFunctions";
import { ControlOnChangeArguments, DispatchEvent, State } from "../../../library/Core/SmartTypes";
import { axiosClient } from "../../../library/Service/axiosClient";


export const handleCreate = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
        event.preventDefault();

        console.log(state['data'])
        if (!isFormValid(state, dispatch)) {
            return;
        }

        console.log("state_eventId",state)

        let eventAdd = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatespeakerinfo`;
        axiosClient()
            .post(`${eventAdd}`, {
                eventId : '22622429-ca14-4c61-b072-0b7db271fb72',
                speakerinfo: JSON.stringify(state['data']['speakerinfo']),
                
                 
             })
             .then((res: any) => {

                console.log(res.data.eventId)
                alert(res.data.message)

                if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
                // Navigate(`/event/audience-category?id=${res.data.eventId}`);
                console.log("here redirect code need to be implemented");

            });

        
    }

export const onOrganizationChange = (props: ControlOnChangeArguments) => {
    alert(props.value)
};