import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import SessionContext from '../../../library/Core/SessionContext';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import Layout from './Layout';
import { format, isValid } from 'date-fns';
import Swal from 'sweetalert2';

const AddNew = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    const { sessionState } = useContext(SessionContext);
    
    const userType = sessionState?.userTypeCode;
    const userId = sessionState?.id ?? sessionState?.userId;
    // const queryParams = new URLSearchParams(window.location.search);
    const { eventId } = useParams();
    const [isExit, setIsExit] = useState(false);
    // const eventId = queryParams.get('id');
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleSaveAndNext,
            SAVE_AND_EXIT: handleSaveAndExit,
            SAVE_AND_NEXT: handleSaveAndNext,
        },
    } as any);

    // useEffect(() => {
    //     dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    // }, []);


    async function handleSaveAndExit(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        
        try {
            const resp = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            if ( ! isEmpty(resp?.id) && resp?.success == 1 ) {
                let navigateToPath = getEventsListingPath();
                // navigate(navigateToPath, { replace: true });
                window.location.href = navigateToPath;
            }
        } catch (error) {
            console.error("Error while save and exit:", error);
        }
    }

    async function handleSaveAndNext(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();

        try {
            const resp = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            if ( ! isEmpty(resp?.id) && resp?.success == 1 ) {
                navigate(`/event/speaker-info/${resp?.id}`);
            }
        } catch (error) {
            console.error("Error while save & next:", error);
        }
    }
    
    const getEventsListingPath = () => {
        // console.log(userType, " userType I N N E R ---")
        let navigateToPath = `${process.env.REACT_APP_APP_BASE_URL}/events/upcoming`;
        // if (userType == 'INSTITUTE_ADMIN') {
        //     navigateToPath = `${process.env.REACT_APP_APP_BASE_URL}/events/upcoming`;
        // }
        return navigateToPath;
    }


    async function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        const isnotValiddateFrom = isdateFromnotValid(state, dispatch);
        const isnotValiddateTo = isdateTonotValid(state, dispatch);
        const isnotValidregistrationsActive = isregistrationsActivenotValid(state, dispatch);
        const isnotValidpresenterCategory = ispresenterCategorynotValid(state, dispatch);
        const isnotValideventImage = iseventImagenotValid(state, dispatch);

        let retData = {
            id: '',
            success: 0,
            message: `Oops..! Something went wrong, Please try again in a while..!!`
        }

        if (!isFormValid(state, dispatch) || isnotValiddateFrom || isnotValiddateTo || isnotValideventImage || isnotValidregistrationsActive || isnotValidpresenterCategory) {
            dispatch({ type: 'SHOW_ERRORS' });
            return retData;
        }

        let eventAdd = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/save-basic-info`;
       
        const dateFromDate = new Date(state?.data?.addevent?.dateFrom);
        const dateToDate = new Date(state?.data?.addevent?.dateTo);

        const formattedFromDateTime = format(dateFromDate, 'yyyy-MM-dd HH:mm:ss');
        const formattedToDateTime = dateToDate && isValid(dateToDate) ? format(dateToDate, 'yyyy-MM-dd HH:mm:ss') : '';
       
        await axiosClient()
            .post(`${eventAdd}`, {
                eventId: eventId ? eventId : '',
                eventTitle: state['data']['addevent']['eventTitle'],
                eventMode: state['data']['addevent']['eventMode'],
                activeEvent: state['data']['addevent']['activeEvent'],
                dateFrom: formattedFromDateTime,
                dateTo: formattedToDateTime,
                registrationsActive: state['data']['addevent']['registrationsActive'],
                eventType: state['data']['addevent']['eventType'],
                regLink: state['data']['addevent']['regLink'],
                videoLink: state['data']['addevent']['videoLink'],
                presenterCategory: state['data']['addevent']['presenterCategory'],
                eventDesc: state['data']['addevent']['eventDesc'],
                relatedInstitute: state['data']['addevent']['relatedInstitute'],
                eventImage: state['data']['addevent']['eventImage'],
                created_by: userId,
                created_role: userType,
                clientsOnly: state.data.addevent?.clientsOnly,
                showInSuperCounsellor: state.data.addevent?.showInSuperCounsellor,
                instituteType: arrayColumn(state.data?.addevent?.instituteType ?? [], 'instituteType'),
                instituteId: sessionState?.primaryInstitute > 0 ? sessionState?.primaryInstitute : null 
            })
            .then((res: any) => {
                console.log(res, '  R E S ')
                retData.id = res.data.id;
                retData.message = res.data.message;
                retData.success = 1;

                if (!res.data.id) {
                    retData.message = retData.message ?? 'There is some issue in event management. Please try after sometime!';
                    retData.success = 0;
                }

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: `${retData.success == 1 ? 'success' : 'warning'}`,
                    title: `${retData.message}`,
                });

                // if (retData.success == 1) {
                    // navigate(`/event/speaker-info/${res.data.id}`);
                // }
            });


            return retData;
    }

    const isdateFromnotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;     

        if (state.data?.addevent?.dateFrom === null || state.data?.addevent?.dateFrom === undefined) {
            setError('addevent.eventFrom', ['Please enter "From date"'], dispatch);
            returnVal = true;
        }else {
            setError('addevent.eventFrom', [], dispatch);
        }

        return returnVal;
    };

    const isdateTonotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        if (new Date(state.data?.addevent?.dateFrom) >= new Date(state.data?.addevent?.dateTo)) {
            setError('addevent.eventTo', ['Please enter "To Date Greater then From Date"'], dispatch);
            returnVal = true;
            return returnVal;
        }

        const fromDate = new Date(state.data?.addevent?.dateFrom);
        const minToDate = fromDate.setMinutes(fromDate.getMinutes() + 15); // Add 15 min min duration
        if (new Date(state.data?.addevent?.dateTo) < new Date(minToDate)) {
            setError('addevent.eventTo', ['Event duration must be at least 15 minutes'], dispatch);
            returnVal = true;
        }

        if ( !returnVal ) {
            setError('addevent.eventTo', [], dispatch);
        }

        return returnVal;
    };

    const isregistrationsActivenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;
        const errorMessages = [] as any[];

        if (state.data?.addevent?.registrationsActive == 1) {
            if (state.data?.addevent?.regLink === null || state.data?.addevent?.regLink === undefined) {
                setError('addevent.regLink', ['Please enter "Registrations Link"'], dispatch);
                returnVal = true;
            } else {
                setError('addevent.regLink', [], dispatch);
                returnVal = false;
            }
        } else {
            setError('addevent.regLink', [], dispatch);
            returnVal = false;
        }

        if ( ! isEmpty(state.data?.addevent?.regLink) ) {
            const regex = /^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;

            if ( ! regex.test(state.data?.addevent?.regLink) ) {
                setError('addevent.regLink', ['Please enter valid url for "Registrations Link"'], dispatch);
                returnVal = true;
            } else {
                setError('addevent.regLink', [], dispatch);
                returnVal = false;
            }
        } else if (state.data?.addevent?.registrationsActive != 1) {
            setError('addevent.regLink', [], dispatch);
            returnVal = false;
        }
        
        dispatch({ type: 'SHOW_ERRORS' });

        return returnVal;
    };

    const ispresenterCategorynotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        const institutePresenterCategories = ['1','4','5'];
        const instituteEventTypes = ['2','6'];
        if (institutePresenterCategories.includes(state.data?.addevent?.presenterCategory) || instituteEventTypes.includes(state.data?.addevent?.eventType)) {
            if (state.data?.addevent?.relatedInstitute === null || isEmpty(state.data?.addevent?.relatedInstitute) || state.data?.addevent?.relatedInstitute === undefined) {
                setError('addevent.relatedInstitute', ['Please enter "Relate Institute name"'], dispatch);
                returnVal = true;
            }
        } else {
            setError('addevent.relatedInstitute', [], dispatch);
            returnVal = false;
        }

        return returnVal;
    };

    const iseventImagenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        var returnVal = false;

        // Check if the image is valid
        const image = state.data?.addevent?.eventImage; // Assuming there's an image in your state

        if (!image) {
            setError('addevent.eventImage', ['Please upload the event cover image'], dispatch);
            returnVal = true;
        }
        
        return returnVal;
    };

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { addevent : data  },
                domain,
                routeInfo,
            },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default AddNew;
