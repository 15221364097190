import { axiosClient } from '../../../library/Service/axiosClient';
import pageConfig from './PageConfig.json';

class ConversationReportService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ConversationReportService.getDefaultSearchCriteria();

        return Promise.all([
            ConversationReportService.getPageConfig(),
            ConversationReportService.search(searchCriteria, params.pageName),
           // AlumniEngagementService.getSchoolSearchFilterDomains(params.pageName),
        ]).then((values) => {
            state.formConfig = values[0];
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria },
                //filterDomains: values[2].data,
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, reportType: string): any {
        const payload = ConversationReportService.sanitizeSearchCriteria(searchCriteria);
        return axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/all-conversation-list`, payload);
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        const criteria = { ...searchCriteria };
        for (const [key, value] of Object.entries(searchCriteria)) {
            criteria[key] = Array.isArray(value) && typeof value[0] === 'object' ? value.map((obj) => Object.values(obj)[0]) : value;
        }
        return criteria;
    }

    static searchCount(searchCriteria: any, pageName: string) {
        const criteria = this.sanitizeSearchCriteria(searchCriteria);
        return axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/all-conversation-list-count`, criteria);
    }

    static searchTwowayconversationCount(searchCriteria: any, pageName: string) {
        const criteria = this.sanitizeSearchCriteria(searchCriteria);
        return axiosClient().post(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/twoway-conversation-list-count`, criteria);
    }


    static getDefaultSearchCriteria(): any {
        return {
            createdFromDate: [],
            createdToDate: '',
            updatedFromDate:[],
            updatedToDate:'',
            senderType:'',
            receiverType:'',
        };
    }
}

export default ConversationReportService;
