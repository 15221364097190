import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
//D:\userreactappuei_old\src\features\alumni\alumni-profile\sections\admin\ProfileMarker\ProfileMakerService.js
import ProfileMarkerService from '../../../features/alumni/alumni-profile/sections/admin/ProfileMarker/ProfileMakerService.js';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
import { handleCreate, onOrganizationChange } from './SpeakerInfoActions';

const SpeakerInfo = () => {
    const { globalState } = useContext(GlobalContext);
   // const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    // const queryParams = new URLSearchParams(window.location.search);
    // const eventId = queryParams.get('id');
    const { eventId } = useParams();
    const { allUsers } = ProfileMarkerService();
    
    if(!eventId){
        navigate(`/event/add`);
    }
    
    const [state, dispatch] = useImmerReducer(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        domain: new Map(),
        actions: {
          SAVE: handleCreate,
        //   organization: (props : any) => {
        //     console.log('Organization action triggered with:', props);
        //     onOrgChange(props);
        // },
          organization: onOrganizationChange,
        },
      } as any);
      
    //   async function onOrgChange(props: ControlOnChangeArguments) {
    //    alert(' onOrgChange call');
    // }

    // function handleCreate(
    //     event: React.SyntheticEvent<HTMLButtonElement>,
    //     state: State,
    //     dispatch: (dispatchEvent: DispatchEvent) => void,
    //     globalState: any,
    //     globalDispatch: (dispatchEvent: DispatchEvent) => void
    // ) {
    //     event.preventDefault();

    //     console.log(state['data'])
    //     if (!isFormValid(state, dispatch)) {
    //         return;
    //     }

    //     let eventAdd = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatespeakerinfo`;
        

    //     axiosClient()
    //         .post(`${eventAdd}`, {
    //             eventId : eventId,
    //             speakerinfo: JSON.stringify(state['data']['speakerinfo']),
                
                 
    //          })
    //          .then((res: any) => {

    //             console.log(res.data.eventId)
    //             alert(res.data.message)

    //             if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
    //             navigate(`/event/audience-category?id=${res.data.eventId}`);

    //         });

        
    // }

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Simulated data fetching for users from the same institute
                const usersListFromSameInstitute: any = []; // Replace with real data or API call
                const domainMap = new Map([...globalState.domain]); // Clone global state domain

                // Update domain map
                domainMap.set('USERS_FROM_SAME_INSTITUTE', usersListFromSameInstitute || []);

                // Add user-specific domains
                const domain = addUserSpecificDomains(new Map([...domainMap]), data);
        
                    dispatch({
                        type: 'DATA_INIT',
                        //type: 'SET_DOMAIN',
                        payload: {
                            formConfig,
                            data: { speakerinfo: data || { ...data, organisation: '', speakername: '' } },
                            domain: domain,
                            routeInfo
                        },
                    });
                    console.log('Domain before update first:', state.domain);
                    console.log('Domain after update first:', domainMap);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const domainMap = new Map([...state.domain]);
        console.log(domainMap.get('USERS_FROM_SAME_INSTITUTE'), 'get USERS_FROM_SAME_INSTITUTE ');
    }, [state.domain])
    

    // useEffect(() => {
    //     if(state.data?.user?.instituteId != undefined){
    //         // alert('Test')
    //         const fetchData = async () => {
    //             try {
    //                 const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/user/get-programs-by-institute-id`, {
    //                     institute_id: state.data?.user?.instituteId,
    //                 });
    //                 const programs = response.data.data.programs;
    //                 let program_values = [];
    //                 for(let i = 0; i < programs.length;i++)
    //                 {
    //                     program_values[i] = {'label' : programs[i]['name'],'value': programs[i]['id']};
    //                 }
                    
    //                 dispatch({
    //                     type: 'SET_DOMAIN',
    //                     payload: { key: 'INSTITUTE_ATTENDED_PROGRAM_CODE', value: getinstituteList('INSTITUTE_ATTENDED_PROGRAM_CODE', program_values) },
    //                 });
    //             } catch (error) {
    //                 console.error('Error fetching program data:', error);
    //             }
    //         }

    //         fetchData();
    //     }
    // }, [state.data?.user?.instituteId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
             
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default SpeakerInfo;
