import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getControlFromFactory } from './ControlFactory';
import { validateFormField } from './FormFieldValidation';
import { ControlValueChangeArgs, DispatchEvent, DomainElement, FormControl, ObjectWithKeys, Page, State } from './SmartTypes';
import { axiosClient } from '../Service/axiosClient';
import pako from 'pako'; // Importing pako for zlib/gzip decompression

export const isEmpty = (value: any): any => {
    if (value === null || value === undefined) {
        return true;
    }

    if (value === '') return true;

    if (Array.isArray(value)) {
        // return value.length === 0;
        if (value.length === 0) {
            return true;
        }

        // checking whether the inner elements are empty to restrict empty nested array to be resulted as not empty;
        let isEmptyFlag = true;
        for (let i = 0; i < value.length; i++) {
            isEmptyFlag = isEmpty(value[i]);
            if (!isEmptyFlag) {
                return isEmptyFlag;
            }
        }

        return isEmptyFlag;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0 && value.constructor === Object;
    }

    if (value instanceof Map && value.size === 0) {
        return true;
    }

    return false;
};

export const getControlValueFromState = (
    key: string,
    state: State,
    defaultReturnValue: any = ''
): any[] | string | number | undefined | any => {
    if (isEmpty(state.data)) return [];
    if (key === '') return state.data as State;
    try {
        return key.split('.').reduce((a, c) => a[c], state.data);
    } catch (e) {
        return defaultReturnValue;
    }
    // return undefined;
};

// Hard coding here... Need to change this
export const getParentData = (key: string, state: State, defaultReturnValue: any = ''): any[] | string | number | undefined | any => {
    if (key === 'user.schoolId') {
        return getControlValueFromState('user.schoolId', state, null);
    }
    if (key.split('.').includes('schoolId')) return getControlValueFromState('schoolId', state, null);
    return getControlValueFromState(key, state);
};

export const handleControlValueChange = (args: ControlValueChangeArgs) => {
    const { dataKey, parentDataKey, dispatch, state, control, value } = args;

    const row = getControlValueFromState(parentDataKey, state as State);

    // Validate the control value for the validations error
    const errorMessages = validateFormField(control, value, state, control.props.label, dataKey, row);

    // Call the associated function
    if (state.actions[control.id])
        state.actions[control.id]({ control, value, row, dataKey, parentDataKey, state, dispatch, errorMessages });

    dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, name: control.id, value, errorMessages } });
};

export const convertDomainArrayToMap = (domain: DomainElement[]) => {
    const domainMap = new Map<string, DomainElement[]>();

    domain.map((element) => {
        if (domainMap.has(element.categoryCode)) domainMap.get(element?.categoryCode)?.push(element);
        else domainMap.set(element.categoryCode, [element]);
    });

    return domainMap;
};

export const getDataStructureFromControls = (controls: FormControl[]) =>
    controls.reduce((accumulator, control) => Object.assign(accumulator, { [control.id]: undefined }), {});

// export const getDomainValueForCode = (code: string, domain: any[]) => {
//     if (isEmpty(code) || isEmpty(domain)) return '';
//     return domain?.find((element) => element.code === code)['value'];
// };

export const getDomainValueForCode = (code: string, domainCategoryCode: string, state: State) => {
    if (domainCategoryCode === 'AUTO_GEN_BASED_ON_CODE') return code;
    if (isEmpty(code) || isEmpty(domainCategoryCode)) return '';
    return (state?.domain?.get(domainCategoryCode)?.find((element) => element.code == code) as DomainElement)?.value ?? code;
};

export const getFlexColDomainValueForCode = (code: string, domainCategoryCode: string, state: State) => {
    if (domainCategoryCode === 'AUTO_GEN_BASED_ON_CODE') return code;
    if (isEmpty(code) || isEmpty(domainCategoryCode)) return '';
    return (state?.domain?.get(domainCategoryCode)?.find((element) => element.code == code) as DomainElement)?.flexCol ?? '';
};

export const getDomainValueForCodeUsingParent = (code: string, domainCategoryCode: string, state: State, parent: any) => {
    if (domainCategoryCode === 'AUTO_GEN_BASED_ON_CODE') return code;
    if (isEmpty(code) || isEmpty(domainCategoryCode || isEmpty(parent))) return '';

    return (
        (state?.domain?.get(domainCategoryCode)?.find((element) => element.code == code && element.parentCode == parent) as DomainElement)
            ?.value ?? code
    );
};

export const getDomainValueForCategoryCode = (domainCategoryCode: string, state: State) => {
    if (isEmpty(domainCategoryCode)) return [];

    const elements = (state?.domain?.get(domainCategoryCode) as DomainElement[]) ?? [];
    // console.log(elements);
    return elements.map((element) => ({
        code: element.code,
        value: element.value,
        flexCol: element.flexCol,
        parentCode: element.parentCode,
    }));
};

export const range = (from: number, to: number) => [...Array(to - from)].map((_, i) => i + from);

export const getFieldValuesConcatenatedFromRecord = (record: ObjectWithKeys, config: FormControl[], fieldNames: string, state: State) => {
    return fieldNames
        .split(',')
        .map((value) => value.trim())
        .map((fieldName) => {
            const controlConfig = config.find((ctrl) => ctrl.id === fieldName);
            return controlConfig?.props.domainCategoryCode
                ? getDomainValueForCode(record[fieldName] as string, controlConfig?.props.domainCategoryCode, state)
                : record[fieldName];
        })
        .join(' - ');
};

export const findTheRightDataKey = (control: FormControl, dataKey: string, parentDataKey: string) => {
    if (!control?.dataKey) return dataKey;
    return control?.dataKey === 'USE_PARENT' ? parentDataKey : control.dataKey;
};

// TODO: Merge this with evaluateExpressionWithValue
export const evaluateExpression = (expression: string, model: any, row: any = {}) => {
    if (isEmpty(model) || isEmpty(expression)) return;
    const dynamicFunc = (expression: string, model: any, row: any) => new Function('model', 'row', 'return ' + expression + ';');
    return dynamicFunc(expression, model, row)(model, row);
};

export const evaluateExpressionWithValue = (expression: string, data: any, model: any, row: any = {}) => {
    if (isEmpty(model) || isEmpty(expression)) return;
    const dynamicFunc = (expression: string) => new Function('model', 'row', 'data', 'return ' + expression + ';');
    console.log(dynamicFunc, ' dynamicFunc ');
    return dynamicFunc(expression)(model, row, data);
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const camelize = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

export const wordToHyphenCase = (str: string) => str?.replace(' ', '-')?.toLowerCase();

export const getIcon = (type: string, similarAlumni: boolean = true) => {
    type = wordToHyphenCase(type)?.toLowerCase();
    switch (type) {
        case 'award':
            type = similarAlumni ? type : 'medal';
            break;

        case 'exam-score':
            type = similarAlumni ? 'exam' : 'grade';
            break;

        case 'course-completion':
            type = similarAlumni ? 'course' : type;
            break;

        case 'social-service':
            type = similarAlumni ? 'social' : type;
            break;
    }

    return type;
};

// export const isFormValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void, errorKeysToIgnore: any = []) => {
//     if (
//         Object.entries(state?.formValidationErrors)
//             .filter(([key, value]) => !errorKeysToIgnore.includes(key) && value !== undefined)
//             .flat().length > 0
//     ) {
//         dispatch({ type: 'SHOW_ERRORS' });
//         console.log(state?.formValidationErrors);
//         // alert('Please enter the mandatory fields!');
//         return false;
//     }
//     return true;
// };

export const isFormValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void, errorKeysToIgnore: any = []) => {
    const totalErrorCount = Object.entries(state?.formValidationErrors || {})
        .filter(([key, value]) => {
            const foundIgnoreKey = errorKeysToIgnore.find((ignoreKey: string) => {
                const regExp = new RegExp(`^${ignoreKey}(\\.\\d+)?$`); // Regular expression to match "key.<digit>"
                return regExp.test(key);
            });

            if (foundIgnoreKey) return false;
            // If not found and the value is defined, we should count the error (=> return true)
            return !isEmpty(value);
        })
        .reduce((total, [key, value]) => total + (value as any).length, 0);

    if (totalErrorCount > 0) {
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state?.formValidationErrors);
        return false;
    }
    return true;
};

// export const getDomainCategoryCodeForKey = (sectionName: string, key: string, state: State) => {
//     // TODO: controlGroup[0] in below is fishy
//     return state.formConfig?.sectionRepository
//         .find((section) => section.id === sectionName)
//         ?.controlGroup[0].controlGroup.find((control) => control.id === key)?.props.domainCategoryCode;
// };

export const formatDate = (date: Date, format: string) => {
    const map: any = {
        mo: date.toLocaleDateString(undefined, { month: 'short' }),
        mm: date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
        dd: date.getDate(),
        // yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear(),
    };

    return format.replace(/mm|mo|dd|yyyy/gi, (matched) => map[matched]);
};

export const getControlPropsForCustomBuild = (
    sectionId: string,
    controlId: string,
    pageConfig: Page,
    handleChangeEvent: () => void = () => {}
) => {
    const formSection = pageConfig.sectionRepository.find((section) => section.id === sectionId);

    return {
        control: formSection?.controlGroup.find((control) => control.id === controlId) as FormControl,
        dataKey: `${formSection?.dataId ? formSection?.dataId : formSection?.id}.${controlId}`,
        parentDataKey: formSection?.dataId ? formSection?.dataId : formSection?.id,
        handleChangeEvent,
    };
};

export const getSectionConfig = (sectionName: string, state: State) =>
    state?.formConfig?.sectionRepository.find((section) => section.id === sectionName);

export const getDataKeyForSection = (section: string, state: State) => {
    if (getSectionConfig(section, state)?.dataId === 'NO_ID') return '';

    return getSectionConfig(section, state)?.dataId
        ? (getSectionConfig(section, state)?.dataId as string)
        : (getSectionConfig(section, state)?.id as string);
};

export const getChildKey = (control: FormControl, parentDataKey: string) => {
    if (!control.id) return parentDataKey;
    if (control.id && control?.dataId === 'USE_PARENT') return parentDataKey;
    if (control.id && control?.dataId?.length > 0 && control?.dataId !== 'USE_PARENT') return control.dataId;
    return isEmpty(parentDataKey) ? control.id : parentDataKey + '.' + control.id;
};

export const getControl = (control: FormControl, dataKey: string, state: State, padding: string) => {
    const childDataKey = control?.dataPath ? control.dataPath : getChildKey(control, dataKey);
    return getControlFromFactory(control, dataKey, childDataKey, state as State, null, padding);
};

export const getArrayIndexKey = (id: any) => (id ? id : uuidv4());

export const getUUIDs = (count: number) => {
    const uuids = [];
    for (let i = 0; i < count; i++) {
        uuids.push(uuidv4());
    }
    return uuids;
};

export function debounce(func: any, delay: number) {
    let timeout: any;
    const debounced = (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, delay);
    };
    debounced.cancel = () => {
        clearTimeout(timeout);
    };
    return debounced;
}

export function formatFullName(firstName: string, lastName: string) {
    const capitalize = (str: string) => {
        if (!str) return '';
        return str
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const formattedFirstName = capitalize(firstName);
    const formattedLastName = capitalize(lastName);

    return `${formattedFirstName} ${formattedLastName}`.trim();
}

export function toSentenceCase(str: string) {
    if (isEmpty(str)) return '';
    //need to check if param is not a number and not emapty
    const lowerCaseStr = str?.toLowerCase();
    const words = lowerCaseStr.split(' ');

    const sentenceCaseWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const sentenceCaseStr = sentenceCaseWords.join(' ');

    return sentenceCaseStr;
}

export const getDateDDMMYYYY = (date: Date) => moment(date).format('DD-MM-YYYY');
// export const getDateDDMMYYYYHHMM = (date: Date) => moment(date).format('DD-MM-YYYY hh:mm A');
// export const getDateDDMMYYYYHHMM = (date: Date) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm A');
export const getDateDDMMYYYYHHMM = (dateString: Date) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
    //moment.utc(date).local().format('DD-MM-YYYY hh:mm A')};
};
export const parseDateStr = (dateStr: any, format: string = 'YYYY-MM-DD'): string => {
    if (dateStr == null) return '';
    // Trim the string and convert to upper case
    dateStr = dateStr.trim().toUpperCase();

    // Check if dateStr is only N
    if (dateStr === 'N') {
        return moment().format(format);
    }

    // Check if dateStr has valid format
    let regex = /^N([+-])(\d+)([DMY])$/;
    let matches = dateStr.match(regex);

    if (!matches) {
        throw new Error('Invalid format');
    }

    // Parse the operation, value and unit
    let operation = matches[1];
    let value = parseInt(matches[2]);
    let unit = matches[3].toLowerCase(); // moment.js expects lower case units

    // Perform operation
    let date;
    if (operation === '+') {
        date = moment().add(value, unit as moment.unitOfTime.DurationConstructor);
    } else if (operation === '-') {
        date = moment().subtract(value, unit as moment.unitOfTime.DurationConstructor);
    } else {
        throw new Error('Invalid operation');
    }

    // Format and return date
    return date.format(format);
};

export const setError = (dataKey: string, errorMessages: string[], dispatch: any) => {
    dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
};

export function safelyAssign(target: any, path: (string | number)[], value: any): void {
    if (!Array.isArray(path)) {
        throw new Error('Path must be an array');
    }

    let obj: any = target;
    for (let i = 0; i < path.length - 1; i++) {
        if (obj[path[i]] === undefined) {
            return; // If any part of the path is undefined, abort assignment.
        }
        obj = obj[path[i]];
    }

    // Do the assignment if possible.
    if (obj[path[path.length - 1]] !== undefined) {
        obj[path[path.length - 1]] = value;
    }
}

export function isValidBusinessObject(data: any, mandatoryKeys: any, uniqueKey: any, dataKey: string, dispatch: any, name: string = '') {
    let ids = new Set();
    let isValid = true;
    let atLeastOneCompleteRow = false;

    data.forEach((item: any, index: number) => {
        // let isRowFilled = Object.values(item).some((value: any) => !isEmpty(value));
        let isRowFilled = mandatoryKeys.some(
            (key: any) =>
                (item.hasOwnProperty(key.id) && !isEmpty(item[key.id])) ||
                (key.manualColumnId && item.hasOwnProperty(key.manualColumnId) && !isEmpty(item[key.manualColumnId]))
        );

        let isRowComplete = mandatoryKeys.every(
            (key: any) =>
                (item.hasOwnProperty(key.id) && !isEmpty(item[key.id])) ||
                (key.manualColumnId && item.hasOwnProperty(key.manualColumnId) && !isEmpty(item[key.manualColumnId]))
        );

        mandatoryKeys.forEach((key: any) => {
            if (
                isRowFilled &&
                (!item.hasOwnProperty(key.id) || isEmpty(item[key.id])) &&
                (!key.manualColumnId || !item.hasOwnProperty(key.manualColumnId) || isEmpty(item[key.manualColumnId]))
            ) {
                setError(`${dataKey}.${index}`, [`Please enter "${key.label}" ${isEmpty(name) ? '' : ' for this record'}`], dispatch);
                isValid = false;
            } else {
                // No error for this key, clear previous error if exists
                setError(`${dataKey}.${index}`, [], dispatch);
            }
        });

        if (isRowComplete) {
            atLeastOneCompleteRow = true;
        }

        if (item.hasOwnProperty(uniqueKey.id)) {
            if (ids.has(item[uniqueKey.id])) {
                setError(`${dataKey}.${index}.${uniqueKey.id}`, [`Please remove duplicate entry for ${uniqueKey.label}`], dispatch);
                isValid = false;
            } else {
                if (!isEmpty(item[uniqueKey.id])) {
                    ids.add(item[uniqueKey.id]);
                }
                // No error for this key, clear previous error if exists
                setError(`${dataKey}`, [], dispatch);
            }
        }
    });

    if (!atLeastOneCompleteRow) {
        setError(`${dataKey}`, [`Please add at least one ${name ?? 'item'} with all mandatory fields`], dispatch);
        isValid = false;
    } else {
        // No error for this, clear previous error if exists
        setError(`${dataKey}`, [], dispatch);
    }

    return isValid;
}

export const convertToPercentage = (data: any) => {
    // First, determine the total count
    const totalCount = data.reduce((sum: number, item: any) => sum + item.value, 0);

    // Map over the array and compute the percentage for each entry
    return data.map((item: any) => ({
        label: item.label,
        value: parseFloat(((item.value * 100) / totalCount).toFixed(1)), // Rounded to one decimal
        actualValue: item.value,
    }));
};

// Note: Magic happens here.. handle with care
export const getFilteredDomain = (domainCode: string, state: State, parentCode: any = []) => {
    const getRealDomainCode = (domainCode: string) => {
        switch (domainCode) {
            case 'PREFERENCE_COUNTRY_CODE':
                return 'COUNTRY_CODE';
            case 'PREFERENCE_FIELD_OF_STUDY_CODE':
                return 'FIELD_OF_STUDY_TYPE_CODE';
            default:
                return domainCode;
        }
    };

    const domain = state.domain.get(getRealDomainCode(domainCode));
    if (!domain) return [];

    const domainToFilterMap: any = {
        PAST_YEAR_CODE: 'passOutYear',
        CLASS_CODE: 'classCode',
        CLASS_12_CURRICULUM_CODE: 'curriculumCode',
        PROGRAM_LEVEL: 'pursuingEducationLevelCode',
        COUNTRY_CODE: 'residingCountryCode',
        CITY_CODE: 'residingCityCode',
        PREFERENCE_COUNTRY_CODE: 'preferenceCountryCode',
        PREFERENCE_FIELD_OF_STUDY_CODE: 'preferenceFieldOfStudyCode',
        FIELD_OF_STUDY_TYPE_CODE: 'fieldOfStudyCode',
        SCHOOL_CODE: 'collegeCampusCode',
        INSTITUTE_PROGRAM_CODE: 'programId',
        INSTITUTE_DOMAIN: 'instituteId',
    };

    const filterDomainCodes = state.data.filterDomains[domainToFilterMap[domainCode]];
    let sortedDomainCodes = filterDomainCodes;

    if (domainCode === 'PAST_YEAR_CODE' && filterDomainCodes) {
        sortedDomainCodes = [...filterDomainCodes].sort((a: any, b: any) => b - a); // Sort in descending order
    }

    return sortedDomainCodes
        ?.map((code: string) => {
            return domain.find((item: any) => {
                if (item.code != code) return false;

                // Check if parentCode is an array
                if (Array.isArray(parentCode)) {
                    if (!isEmpty(parentCode) && !parentCode.map(String).includes(String(item.parentCode))) return false;
                } else {
                    if (!isEmpty(parentCode) && String(item.parentCode) != String(parentCode)) return false;
                }

                return true;
            });
        })
        .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
        .filter((item: any) => item);
};

export const getFuzzySearchCriteria = (fuzzySearchText: any) => {
    if (isEmpty(fuzzySearchText)) return {};

    const isName = (input: string) => /^[a-zA-Z\s]+$/.test(input);
    const isEmail = (input: string) => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(input);
    const isMobile = (input: string) => /^\+?\d{1,4}[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(input);

    if (isEmail(fuzzySearchText)) return { registeredEmailId: fuzzySearchText };
    if (isMobile(fuzzySearchText)) return { mobileNumber: fuzzySearchText };
    if (isName(fuzzySearchText)) return { name: fuzzySearchText };
    return { name: fuzzySearchText };
};

export const canEditProfile = (profileId: string, sessionState: any) => {
    const loggedInUserId = sessionState?.id;
    const loggedInUserTypeCode = sessionState?.userTypeCode;

    if (loggedInUserId === profileId) {
        return true;
    }

    if ((loggedInUserTypeCode === 'STUDENT' || loggedInUserTypeCode === 'ALUMNI') && loggedInUserId !== profileId) {
        return false;
    }
};

export const arrayColumn = (a: any, i: any, ok?: any) => {
    return (
        a
            .reduce((c: any, v: any, k: any) => (ok === undefined ? [(c[k] = v[i]), c][1] : [(c[v[ok]] = i === null ? v : v[i]), c][1]), [])
            // .map((s: any) => s?.trim()); // @by RG @note trim function throws an error if the type of variable s is not string;
            .map((value: any) => (typeof value === 'string' ? value.trim() : value))
    );
};

/**
 * @func arrayUnique returning unique array;
 * @param {Array} arr
 * @param {boolean} omitCase
 * @returns unique array;
 * @dt 8th.Aug,2K23 @by RG;
 */
export const arrayUnique = (arr: any, omitCase: boolean) => {
    if (!omitCase) {
        return [...arr.values()];
    }

    arr = new Map(
        arr.map((s: any) => {
            return [s?.toLowerCase(), s];
        })
    );

    return [...arr.values()];
};

/**
 * @func getTimeAgo returning time ago;
 * @param {string} timestamp
 * @returns time ago
 * @dt 12th.Aug,2K23 @by RG;
 */
export const getTimeAgo = (timestamp: any) => {
    return moment(timestamp).fromNow();
};

/**
 * @func triggering click event on next button on hitting enter key;
 * @param event key down event;
 * @dt 19th.Sep,2K23 @by RG;
 */
export const handleNextBtnClickOnEnter = (event: any) => {
    if (event.key === 'Enter') {
        // Prevent the default Enter key behavior (e.g., form submission)
        event.preventDefault();

        const nextButton = document.querySelector('.nextbtn');

        // Check if the element exists
        if (nextButton) {
            // Create and dispatch a click event on the element
            const clickEvent = new Event('click', { bubbles: true });
            nextButton.dispatchEvent(clickEvent);
        } else {
            // Handle the case where the 'nextbtn' element is not found
            console.error("Element with class 'nextbtn' not found.");
        }
    }
};

export const initNextBtnClickOnEnterEvent = () => {
    // Add the event listener
    window.addEventListener('keydown', handleNextBtnClickOnEnter);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('keydown', handleNextBtnClickOnEnter);
    };
};

export function numberToOrdinal(n: number) {
    const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * creating dynamic file name for file to be downloaded;
 * @param pageName page name;
 * @param data data to be exported;
 */
export function getDownloadFileName(pageName: string, data: any) {
    let fName = 'download';

    switch (pageName) {
        case 'internal-admin-benefits-report':
            fName = 'Benefits report';
            break;
        case 'conversation-report':
                fName = 'Conversation Report';
                break;
    }

    return `${fName} (${data.length})`;
}

export async function hashEmail(email: string) {
    const msgBuffer = new TextEncoder().encode(email); // Encode as UTF-8
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer); // Hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
    return hashHex;
}

export function isSchoolAdmin() {
    const user = JSON.parse(localStorage.getItem('user') as string);
    const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];
    return schoolAdminRoles.includes(user?.role);
}

export function isSuperAdmin() {
    const user = JSON.parse(localStorage.getItem('user') as string);
    const superAdminRoles = ['SUPER_ADMIN'];
    return superAdminRoles.includes(user?.role);
}

export async function activateUserSession(sessionState: any, sessionDispatch: any) {
    await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/update-status`, { profileStatusCode: 'step-two' });

    let sessionStateData = { ...sessionState, userStatusCode: 'ACTIVE', profileStatusCode: 'COMPLETE' };
    // let instData = [...sessionStateData.institutes];
    // instData[0] = { ...instData[0], userStatusCode: 'ACTIVE', profileStatusCode: 'COMPLETE' };
    // sessionStateData.institutes = instData;

    //axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/update-status`, { profileStatusCode: 'step-two' });
    sessionDispatch({ type: 'SET', payload: sessionStateData });
    return true;
}

export function recentPassoutYear(alumni: any) {
    let recentYear = 0;

    // Check if postGradCollege array exists and has elements
    if (alumni?.postGradCollege?.length > 0) {
        recentYear = alumni?.postGradCollege[0]?.endYear; //for max pasosutyear
    }

    // Check if underGradCollegeJoined exists
    if (recentYear === 0 && alumni?.underGradCollegeJoined?.endYear) {
        recentYear = alumni.underGradCollegeJoined.endYear;
    }
    // Check if selectedInstitute exists
    if (recentYear === 0 && alumni?.selectedInstitute?.endYear) {
        recentYear = alumni.selectedInstitute.endYear;
    }

    return recentYear;
}

export function handleCopyText(text: any, id: any): void {
    navigator.clipboard.writeText(text);
    const element = document.getElementById(id);

    let icon = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
      </svg>
    `;
    if (element) {
        element.innerHTML = icon;
    }
    setTimeout(() => {
        icon = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
        </svg>
        `;
        if (element) {
            element.innerHTML = icon;
        }
    }, 1000);
}

export function getMenuType(data: any) {
    const alumniTypeProgramLevel = ['UG', 'PG', 'Doctoral'];
    let menuType = 'ALUMNI';

    const isAlumni =
        (!isEmpty(data?.pursuingEducationLevelCode) && alumniTypeProgramLevel.includes(data?.pursuingEducationLevelCode)) ||
        (!isEmpty(data?.highestCompletedEducationLevelCode) && alumniTypeProgramLevel.includes(data?.highestCompletedEducationLevelCode));
    if (isAlumni) {
        return menuType;
    }

    if (
        (!alumniTypeProgramLevel.includes(data?.programLevel) && data?.programLevel < 12) ||
        (data?.programLevel == 12 && data?.userTypeCode == 'STUDENT')
    ) {
        menuType = 'STUDENT';
    }

    return menuType;
}

export function extractVideoId(url: string) {
    if (url === undefined) return '';
    const videoIdMatch = url?.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : '';
}

export function getWhiteLabelUrl(sessionState: any) {
    if (sessionState && !isEmpty(sessionState.whitelabelUrl)) {
        return sessionState.whitelabelUrl;
    } else {
        const baseUrl = process.env.REACT_APP_APP_BASE_URL;
        return `${baseUrl}/`;
    }
}

/**
 * @func converting a normal array into associative array;
 * @dt 25th.June,2k24 @by RG;
 */
export function getAssociativeArray(data: any, idx: any) {
    return data.reduce((acc: any, item: any) => {
        acc[item[idx]] = item;
        return acc;
    }, {});
}

/**
 * @func converting a normal array into multi associative array;
 * @dt 18.July,2k24 @by RG;
 */
export function getMultiAssociativeArray(data: any, idx: any, childKey = '') {
    return data.reduce((acc: any, item: any) => {
        const key = item[idx];
        if (!acc[key]) {
            acc[key] = [];
        }
        if (isEmpty(childKey)) {
            acc[key].push(item);
        } else {
            acc[key][childKey] = item;
        }
        return acc;
    }, {});
}

export const triggerGridDataOnChange = (data: any, parentKey: any, dataKey: any) => {
    if (isEmpty(data)) {
        return;
    }
    // Assuming you have an onChange handler defined somewhere
    data.forEach((item: any, index: any) => {
        const event = new Event('change', { bubbles: true });
        const inputElement = document.querySelector(`[name="${parentKey}.${index}.${dataKey}"]`);
        if (inputElement) {
            inputElement.dispatchEvent(event);
        }
    });
};

export const getValueFromPath = (obj: any, path: any) => {
    return path.split('.').reduce((acc: any, part: any) => acc && acc?.[part], obj);
};

/**
 * Checks if a given file URL corresponds to a valid image file based on its extension.
 * Valid image file extensions are `.jpg`, `.jpeg`, `.png`, `.gif`, `.bmp`, and `.webp`.
 *
 * @param {string} fileUrl - The URL or path of the file to be checked. It is expected to be a string that includes the file extension.
 * @returns {boolean} `true` if the file has a valid image extension, otherwise `false`.
 *
 * @example
 * const url1 = "http://example.com/image.jpg";
 * const url2 = "http://example.com/document.pdf";
 *
 * console.log(isValidImageFile(url1)); // Output: true
 * console.log(isValidImageFile(url2)); // Output: false
 *
 * @dt 5th.Sep,2K24 @by RG;
 */
export const isValidImageFile = (fileUrl: any): boolean => {
    const fileExtension = fileUrl.substring(fileUrl.lastIndexOf('.') + 1).toLowerCase();

    // List of valid image file extensions
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    // Check if the file extension is valid
    return validExtensions.includes(fileExtension);
};

/**
 * Checks if a given URL is a valid video URL based on specified platforms.
 *
 * @param {string} url - The URL to validate.
 * @param {Array<string>} [platforms=['youtube', 'vimeo']] - An array of platform names to validate against. Defaults to ['youtube', 'vimeo'].
 * @returns {boolean} - Returns `true` if the URL matches the regex pattern for any of the specified platforms, otherwise returns `false`.
 *
 * @throws {Error} - Throws an error if an unsupported platform is passed.
 *
 * @example
 * // Validate a YouTube URL
 * isValidVideoUrl('https://www.youtube.com/watch?v=dQw4w9WgXcQ'); // true
 *
 * @example
 * // Validate a Vimeo URL
 * isValidVideoUrl('https://vimeo.com/12345678', ['vimeo']); // true
 *
 * @example
 * // Validate a URL with unsupported platform
 * try {
 *     isValidVideoUrl('https://example.com/video', ['unsupportedPlatform']);
 * } catch (e) {
 *     console.error(e.message); // "Platform(s) unsupportedPlatform are not configured"
 * }
 */
export const isValidVideoUrl = (url: string, platforms: Array<string> = ['youtube', 'vimeo']): boolean => {
    // Define regex patterns for supported platforms
    const platformPatterns: { [key: string]: RegExp } = {
        youtube:
            /^(https?\:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.+?\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?$/,
        vimeo: /^(https?:\/\/)?(www\.)?(vimeo\.com\/)((channels\/[\w]+\/)|(groups\/[\w]+\/)|(album\/\d+\/)?(video\/)?|)(\d+)(?:[?&].*)?$/,
    };

    // Check for unsupported platforms
    const unsupportedPlatforms = platforms.filter((platform) => !platformPatterns[platform]);
    if (unsupportedPlatforms.length > 0) {
        throw new Error(`Platform(s) ${unsupportedPlatforms.join(', ')} are not configured`);
    }

    // Validate URL based on the specified platforms
    return platforms.some((platform) => {
        const regex = platformPatterns[platform];
        return regex ? regex.test(url) : false;
    });
};

// export const arrayColumn = (data: [], columnKey: string, indexKey: string) => {
//     if (!Array.isArray(data)) {
//         throw new TypeError('data must be an array');
//     }

//     return data.reduce((result, item) => {
//         const value = item[columnKey];
//         if (indexKey) {
//             const index = item[indexKey];
//             result[index] = value;
//         } else {
//             result.push(value);
//         }
//         return result;
//     }, indexKey ? {} : []);
// }

export const updateMoEngageEvent = async (data: any, url: string) => {
    try {
        const response = await axiosClient().post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/${url}`, {
            userInfo: data,
        });
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error posting user information to MoEngage:', error);
    }
    return false;
};

export const decompressData = (compressedData: any) => {
    try {
        // Convert base64 to a Uint8Array
        const binaryString = atob(compressedData);
        const binaryData = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            binaryData[i] = binaryString.charCodeAt(i);
        }

        // Decompress the binary data using pako
        const decompressedBuffer = pako.inflate(binaryData);
        const decompressedData = new TextDecoder('utf-8').decode(decompressedBuffer);

        const parsedData = JSON.parse(decompressedData);

        // Check if the data follows the new structure
        if (Array.isArray(parsedData) && parsedData[0]?.data_value) {
            return parsedData[0].data_value; // Return the nested 'data_value' array
        }

        return parsedData; // Fallback if the structure is different
    } catch (error) {
        console.error('Error decompressing data:', error);
        return null;
    }
};

// export const getDomainData = async () => {
//     const CACHE_KEY = 'app-domain-data';
//     const TIMESTAMP_KEY = `app-domain-data-timestamp`;

//     // Check if the data is in localStorage and if it's still valid
//     const cachedData = localStorage.getItem(CACHE_KEY);
//     const cachedTimestamp = localStorage.getItem(TIMESTAMP_KEY);
//     const oneDayInMs = 24 * 60 * 60 * 1000;

//     if (cachedData && cachedTimestamp && Date.now() - parseInt(cachedTimestamp) < oneDayInMs) {
//         return decompressData(cachedData); // Use cached data if it's still valid
//     }

//     // Fetch new data if the cache is expired or missing
//     const response = await axiosClient().get(
//         `${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/domain_data`
//     );

//     // Save new data and its timestamp in localStorage
//     localStorage.setItem(CACHE_KEY, response.data);
//     localStorage.setItem(TIMESTAMP_KEY, Date.now().toString());

//     return decompressData(response.data);
// };

export const getDomainData = async () => {
    const response = await axiosClient().get(
        `${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/domain_data`
    );
    return decompressData(response.data);
};