import { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';

const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    // const queryParams = new URLSearchParams(window.location.search);
    // const eventId = queryParams.get('id');
    const { eventId } = useParams();
    const [selectedTab, setSelectedTab] = useState(getSelectedTabOnPageLoad());
    const navigate = useNavigate();
    
    function getSelectedTabOnPageLoad () {
        let tab: string = '';
        switch (state?.routeInfo?.pageName) {
            case 'add-event':
                tab = 'add';
            break;

            case 'update-event-speaker':
                tab = 'speaker-info';
            break;

            case 'update-audience':
                tab = 'audience-category';
            break;

            case 'update-theme':
                tab = 'theme-options';
            break;
        }

        return tab;
    }

    const handleTabClick = (tab: string) => {
        if ( tab == selectedTab) {
            return false;
        }

        setSelectedTab(tab);
        if (eventId) {
            navigate(`/event/${tab}/${eventId}`);
        } else {
            navigate(`/event/${tab}`);
        }
    };

    return (
        <div className="main flex-1">
            <section className="updatebasicinformation">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="border-bottom border-2 custom-border-gray p-3 p-sm-4 pb-3">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                        <h3
                                            data-aos="fade-left"
                                            data-aos-delay="600"
                                            className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                                            Event
                                        </h3>
                                    </div>
                                </div>
                                <div className="job_internship_tab polartabsec radius-6 m-0 px-4">
                                    <div className="xscroll p-0">
                                        <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${state?.routeInfo?.pageName === 'add-event' ? 'active' : ''}`}
                                                    id="basic-details-tab"
                                                    onClick={() => handleTabClick('add')}>
                                                    Basic Details
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${state?.routeInfo?.pageName == 'update-event-speaker' ? 'active' : ''}`}
                                                    id="speaker-info-tab"
                                                    onClick={() => handleTabClick('speaker-info')}>
                                                    Speaker Info
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${state?.routeInfo?.pageName === 'update-audience' ? 'active' : ''}`}
                                                    id="audience-category-tab"
                                                    onClick={() => handleTabClick('audience-category')}>
                                                    Audience Category
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${state?.routeInfo?.pageName === 'update-theme' ? 'active' : ''}`}
                                                    id="theme-options-tab"
                                                    onClick={() => handleTabClick('theme-options')}>
                                                    Theme Options
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row m-0 pt-4 mt-2 JobInternshipListing">
                                    <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="basic-details"
                                            role="tabpanel"
                                            aria-labelledby="basic-details-tab">
                                            <form className="lstCustForm w-100">
                                                <div className="row g-4">{props.children}</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
