import axios from 'axios';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';

export const onSignUp = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const isnotvalidMobile = isMobileNotValid(state, dispatch);
    if (!isFormValid(state, dispatch) || isnotvalidMobile) {
        return;
    }

    dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

    const signupLink = `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/user/create-user`;
    const userStatusCode = 'PROFILE_IN_PROGRESS';

    try {
        const res = await axios.post(signupLink, {
            ...state.data.user,
            profileStatusCode: 'START',
            userStatusCode,
        });
        
        const response = res.data;
        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [response] });

        if (!response.success) {
            return;
        }

        const authResponse = await axios.post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/authenticate-social-login`, {
            registeredEmailId: state.data.user.registeredEmailId,
        });

        if (!authResponse.data.token) {
            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [authResponse.data] });
            return;
        }
        
        const profileCount = authResponse.data.institutes?.filter((institute: any) => institute.userStatusCode !== 'INACTIVE').length ?? 0;

        if (profileCount === 0) {
            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [{ message: "Your profile is not active, Please contact administrator..!" }] });
            return;
        }

        // Send event to Moengage signup
        sendMoEngageEvent(state.data.user);

        localStorage.removeItem('linkedInToken');
        localStorage.removeItem('linkedInId');
        localStorage.removeItem('email');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('profilePicture');
        localStorage.removeItem('loggedInPlatform');

        sessionStorage.setItem('user', JSON.stringify(authResponse.data));
        sessionStorage.setItem('user-jwt-token', authResponse.data.token);

        localStorage.setItem('user', JSON.stringify(authResponse.data));
        localStorage.setItem('user-jwt-token', authResponse.data.token);
        localStorage.setItem('user-logged-out', 'FALSE');

        sessionDispatch({ type: 'SET', payload: authResponse.data });
        window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/select-institute`;

    } catch (err:any) {
        if (err.response && err.response.status === 409) {
            alert('The user already exists.');
        } else {
            console.error(err);
            alert('An error occurred during registration. Please try again later.');
        }
    }
};

export const onMobileNumberChange = (props: ControlOnChangeArguments) => {
    const mobileCountryCode = props.row.mobileCountryCode;
    const mobileNumber = props.value;

    if (mobileNumber.length === 0) {
        props.errorMessages.push('Please enter mobile number');
        return;
    }

    if (mobileCountryCode !== '91') return;

    const errorMessage = 'Please enter a valid mobile number';

    if ((mobileNumber.length !== 10 || parseInt(mobileNumber.charAt(0)) < 6) && mobileCountryCode === 91) {
        props.errorMessages.push(errorMessage);
    }
};

const isMobileNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    if (isEmpty(state.data.user.mobileCountryCode)) {
        setError('user.mobileCountryCode', ['Please enter mobile code'], dispatch);
        return true;
    }

    if (isEmpty(state.data.user.mobileNumber)) {
        setError('user.mobileNumber', ['Please enter mobile number'], dispatch);
        return true;
    }

    return false;
};

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-signup`, {
            userInfo: user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };