import { Dropdown,Tooltip } from 'bootstrap';
import moment from 'moment';
import { format } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { renderToString } from 'react-dom/server';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
import { REPORT_CONFIG } from '../../admin/reports/report-config';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

function formatDate(dateString: any) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

const getProgramlist = (row: any) => {
    // Logic to retrieve the value based on the ID
    // For example, you might fetch the value from an array or an API
    console.log(row.program)

    return 'Unknown Program'; // Default value if ID is not found
};

const ManageReferralGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const [visibleStudents, setVisibleStudents] = useState(state.data.leadList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.leadList] ?? [];
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    
    useEffect(() => {
        console.log(`useEffect 1`);
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        console.log(`useEffect 2`);
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.leadList, sortColumn, sortDirection]);

    useEffect(() => {
        console.log(`useEffect 3`);
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        console.log(`useEffect 4`);
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

   
    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const institute_tool = (row: any) => {
        const mainarr = row.instituteId;
        const instituteIdArray = Array.isArray(mainarr) ? mainarr : [mainarr];
    
        const firstTwoItems = instituteIdArray.slice(0, 2);
        const remainingItems = instituteIdArray.slice(2);
    
        const firstTwoItemsHtml = firstTwoItems.map((item: any, index: any) => {
            return (
                <li style={{ listStyleType: 'none' }} key={index}>{getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) ?? ''}</li>
            );
        });
    
        const listItems = remainingItems.map((item: any, index: any) => {
            return (
                <li key={index}>{getDomainValueForCode(item, 'INSTITUTE_DOMAIN', state) ?? ''}</li>
            );
        });
    
        let secondSpan = null;
        if (listItems.length > 0) {
            const tooltipContent = renderToString(<ol>{listItems}</ol>);
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={tooltipContent}>
                        &amp; {listItems.length} More
                    </a>
                </span>
            );
        }
    
        const institute = (
            <span>
                {firstTwoItemsHtml}
                {secondSpan}
            </span>
        );
    
        return institute;
    };

    const subdepart_tool = (row: any) => {
        if (row.programSubDepartment === null) {
            return <span></span>;
        }
        
        const mainarr = (row.programSubDepartment && row.programSubDepartment.length > 0) ? row.programSubDepartment.split(',') : row.programSubDepartment;
        const SubDepartmentArray = Array.isArray(mainarr) ? mainarr : [mainarr];
    
        const firstTwoItemsHtml = () => (
            <li style={{ listStyleType: 'none' }}>
                {getDomainValueForCode(row.programDepartment, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state) ?? ''}
            </li>
        );
    
        const listItems = SubDepartmentArray.map((item: any, index: any) => {
            return (
                <li key={index}>{getDomainValueForCode(item, 'FIELD_OF_STUDY_TYPE_CODE', state) ?? ''}</li>
            );
        });
    
        let secondSpan = null;
        if (listItems.length > 0) {
            const tooltipContent = renderToString(<ol>{listItems}</ol>);
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={tooltipContent}>
                        {listItems.length} Specializations
                    </a>
                </span>
            );
        }
    
        const institute = (
            <span>
                {firstTwoItemsHtml()} 
                {secondSpan}
            </span>
        );
    
        return institute;
    };
    
    

    
    const getActionNode = (row: any) => {
       //alert(row.track_on);
        const actions = config['ROW_ACTION'];
        
        if (!row.emailId || row.trackOn !== 1) {
            return null; // Don't render ActionSettings if emailId is empty
        }
        
        return actions.length > 1 ? (
            <ActionSettings id={row?.id} actions={actions} onClick={handleAction} /> 
        ) : null;
    }

    const handleAction = (id: string, action: string) => {
        console.log(`handleAction: ${id} / ${action}`);
        handlePerformAction(action, [id]);
    };

    const handlePerformAction = (action: string, lead: any) => {
       //handle if multiple
       switch (action) {
        case 'UPDATE':
            if (lead.length > 20) {
                //added by vp #ags-659
                alert('You can select upto 20 records to send message.');
            } else {
                //setSelectedStudents([]);
                window.open(`/reference/update-track?lead_ref_id=${lead}`);
            }
            break;
        case 'VIEW':
            if (lead.length > 20) {
                //added by vp #ags-659
                alert('You can select upto 20 records to send message.');
            } else {
                //setSelectedStudents([]);
                window.open(`/reference/view-track?lead_ref_id=${lead}`);
            }
            break;
        case 'ACTION':
            if (lead.length > 20) {
                //added by vp #ags-659
                alert('You can select upto 20 records to send message.');
            } else {
                //setSelectedStudents([]);
                window.open(`/lead/lead-profile?id=${lead}`);
            }
            break;

       }
    };
    

    return (
        <> 
         <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                            <div className="d-flex justify-content-start align-items-center customselect_parnt">
                            </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                        <h3 className="font-14 font-500 mb-0 me-2">Total {allRows.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} records</h3>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '1042px' }}>
                        <thead>
                            <tr>
                                <th>Lead Details</th>
                                <th>Referer Details</th>
                                <th>Track Status</th>
                                <th>Source</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {visibleStudents.map((row: any) => (
                            <tr key={row.id}>
                                <td>
                                    <div className="d-flex flex-wrap">
                                         <div className="name d-flex flex-column line-height-18 flex-1">
                                            {/* <a href="javascript:;" className="font-16 font-500">{row.first_name} {row.last_name}</a> */}
                                            {/* <input className="form-check-input me-2" type="checkbox" value="" id="Antony" />*/}
                                                                               
                                              <span data-bs-toggle="tooltip" title={`${row.firstName} ${row.lastName}`} className="singlelinename font-16 lineHeight-16 font-500">{row.firstName} {row.lastName} </span>  <br/>
                                              <span data-bs-toggle="tooltip" title={`${row.mobile}`} className="singlelinename font-16 lineHeight-16 font-500">{row.mobile} </span>  
                                              <span data-bs-toggle="tooltip" title={`${row.relationship}`} className="singlelinename font-16 lineHeight-16 font-500">{row.relationship}</span>
                                    
                                            {/* <span><span>Type</span> - <b className="font-600">{getDomainValueForCode(row.programType, 'PROGRAM_TYPE', state) ?? ''}</b></span>
                                            <span><span>Mode</span> - <b className="font-600">{row.programDeliveryMode}</b></span> */}
                                        </div>
                                    </div>
                                </td>
                    
                               
                                <td>
                                    <div className="font-14 font-500 mb-1"><span data-bs-toggle="tooltip" title={`${row.referralFirstName} ${row.referralLastName}`} className="singlelinename font-16 lineHeight-16 font-500">{row.referralFirstName} {row.referralLastName}</span><br/>
                                  
                                   
                                     </div>
                                    <div className="font-14 font-500"> <span className="font-500">
                                    {row.emailId ? row.emailId : 'N/A'}<br/>
                                    </span></div>
                                </td>
                               
                                <td>
                                    <div className="font-500">
                                    {row.addedOn ? moment(row.addedOn).format('DD-MMM-YYYY') : 'N/A'}
                                    </div>
                                    <div className="font-14 font-500">{row.track_active}</div>
                                    <a className="table-link" href="javascript:;"></a>
                                </td>
                                <td>
                                    <div className="font-500">
                                    {row.source}
                                    </div>
                                        </td>
                                <td>{getActionNode(row)}</td>
                                
                                
                            </tr>
                          ))}
                        <tr ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
        );
    };
    
export default ManageReferralGrid;
