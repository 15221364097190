import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { handlePreview, handleReset, handleSave } from './WidgetConfigurationActions';
import SessionContext from '../../library/Core/SessionContext';
import CurrentLocationCountry from './forms/CurrentLocationCountry';
import TopIndustries from './forms/TopIndustries';
import { triggerGridDataOnChange } from '../../library/Core/SmartFunctions';
import AlumniOccupation from './forms/AlumniOccupation';
import AppealVideo from './forms/AppealVideo';
import CoverBanner from './forms/CoverBanner';
import EnquiryForm from './forms/EnquiryForm';
import AdmissionReferralForm from './forms/AdmissionReferralForm';

const Configuration = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: internal,
        data: data,
        actions: {
            RESET: handleReset,
            SAVE: handleSave,
            PREVIEW: handlePreview,
            REDIRECT: navigate
        },
    } as any);

    let {id, code}: any = useParams();
    const isEditing = !!id;

    if ( typeof code == 'undefined') {
        code = '';
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch({
                    type: 'DATA_INIT',
                    payload: {
                        formConfig,
                        data,
                        domain: new Map([...globalState.domain]),
                        internal,
                        routeInfo,
                    },
                });
                dispatch({ type: 'RESET_VALIDATION_ERRORS' });
            } catch (error) {
                console.error('Error fetching program data:', error);
            }
        };
    
        fetchData();
    }, []);   

    useEffect(() => {
        if (isEditing) {
            let btnList = "RESET,SAVE,PREVIEW";
            const noResetWidgets = ['appeal_video', 'cover_banner', 'enquiry_form', 'admission_referral_form'];
            if (noResetWidgets.includes(code) ) {
                btnList = "SAVE,PREVIEW";
            }
            dispatch({ type: 'FORM_CONFIG', payload: { ...formConfig, "buttons": btnList } });
        }
    }, [isEditing]);

    useEffect(() => {
        setTimeout(() => {
            triggerGridDataOnChange(state?.data?.metricData, "metricData", "metricEntity");
        }, 500);
    }, [state?.data?.metricData])

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {code == 'current_location_country' && !state.flags.isDataLoading && <CurrentLocationCountry />}

            {code == 'top_industries' && !state.flags.isDataLoading && <TopIndustries />}

            {code == 'alumni_occupation' && !state.flags.isDataLoading && <AlumniOccupation />}

            {code == 'appeal_video' && !state.flags.isDataLoading && <AppealVideo />}

            {code == 'cover_banner' && !state.flags.isDataLoading && <CoverBanner />}

            {code == 'enquiry_form' && !state.flags.isDataLoading && <EnquiryForm />}

            {code == 'admission_referral_form' && !state.flags.isDataLoading && <AdmissionReferralForm />}

        </SmartContext.Provider>
    );
};

export default Configuration;
