// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfigaudience.json';
import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';

class AudienceCategoryService {
    static userStatusCode: string;
    static studentId: string;
    static userTypeCode: string;
    static genderCode: string;
    static instituteLogo: string;
    static instituteGroupId: string;
    constructor() {
        // IIFE (Immediately Invoked Function Expression)
        (() => {
            const { sessionState } = useContext(SessionContext);
            AudienceCategoryService.userStatusCode = sessionState?.userStatusCode as any;
            AudienceCategoryService.studentId = sessionState?.studentId as any;
            AudienceCategoryService.userTypeCode = sessionState?.userTypeCode as any;
            AudienceCategoryService.genderCode = sessionState?.genderCode as any;
            AudienceCategoryService.instituteLogo = sessionState?.instituteLogo as any;
            AudienceCategoryService.instituteGroupId = sessionState?.instituteGroupId as any;
            //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        })();

        // Rest of the constructor
    }

    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, pageName } = params;
        return Promise.all([this.getPageConfig(), AudienceCategoryService.get(eventId)])
            .then((values) => {
                console.log(values)
                state.formConfig = values[0];
                state.data = values[1];
                state.routeInfo = { pageName };                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    static async get(eventId: any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        
        let AudienceInfo = { };
        let AudienceInfodata = [ ];
        // const queryParams = new URLSearchParams(window.location.search);
        // const eventId = queryParams.get('id');
        
        if (eventId) {
            AudienceInfodata = (await axiosClient().get(`${process.env.REACT_APP_LEAD_SERVER}/marketevent/getaudienceinfo/${eventId}`))?.data; 
            
            const Audiencedata = AudienceInfodata?.data;
            
            if (Audiencedata){
                AudienceInfo = AudienceCategoryService.toAudienceto(AudienceInfodata);
            }else{

                AudienceInfo = { };
            }
        }
        console.log(AudienceInfo)

        return Promise.resolve(AudienceInfo);
    }


    static toAudienceto(AudienceInfo: any) {
       
        
        AudienceInfo.audienceCategory1 = AudienceInfo.data.audience_category;
        AudienceInfo.educationLevelCode = AudienceInfo.data.education_level;
        AudienceInfo.programId = AudienceInfo.data.program_type;
        AudienceInfo.workingStatusCode = AudienceInfo.data.working_status;
        AudienceInfo.batchyear = AudienceInfo.data.batch;
        // eventinfo.registrationsActive = eventinfo.data.reg_status;
        // eventinfo.eventType = eventinfo.data.event_type;
        // eventinfo.registrationLink = eventinfo.data.reg_link;
        // eventinfo.videoLink = eventinfo.data.video_link;
        // eventinfo.presenterCategory = eventinfo.data.presenter_category;
        // eventinfo.instituteId = eventinfo.data.related_institute;


        return AudienceInfo;
    }

   
    
 
}
export default AudienceCategoryService;
