import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Define the toolbar options and formats outside the component
const quillModules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ color: [] }, { background: [] }],              // dropdown with defaults from theme
    [{ script: 'sub' }, { script: 'super' }],         // superscript/subscript
    [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],             // outdent/indent
    [{ direction: 'rtl' }],                           // text direction
    [{ align: [] }],
    ['link', 'image', 'video'],                       // link and image, video
    ['clean']                                         // remove formatting button
  ],
};

const quillFormats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'script',
  'header', 'blockquote', 'code-block',
  'list', 'bullet', 'indent',
  'direction', 'align',
  'link', 'image', 'video',
];

const EmailEditor: React.FC = () => {
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const previewRef = useRef<HTMLDivElement>(null);

  const handleSave = () => {
    console.log('Saved HTML Content:', htmlContent);
  };

  const handleChange = (content: string) => {
    setHtmlContent(content);
  };

  const handlePreviewToggle = () => {
    if (previewRef.current) {
      setHtmlContent(previewRef.current.innerHTML);
    }
    setIsPreview(!isPreview);
  };

  return (
    <div>
      <h2>Email HTML Template Editor</h2>

      {/* Editor or Preview Mode */}
      {!isPreview ? (
        <ReactQuill
          value={htmlContent}
          onChange={handleChange}
          modules={quillModules}
          formats={quillFormats}
          placeholder="Paste or type your HTML here, and use the toolbar to format."
        />
      ) : (
        <div
          ref={previewRef}
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          style={{
            border: '1px solid #ddd',
            padding: '10px',
            minHeight: '300px',
            fontFamily: 'Arial, sans-serif',
          }}
        />
      )}

      {/* Control Buttons */}
      <div style={{ marginTop: '10px' }}>
        <button onClick={handlePreviewToggle}>
          {isPreview ? 'Edit HTML' : 'Preview'}
        </button>
        <button onClick={handleSave}>Save Changes</button>
      </div>
    </div>
  );
};

export default EmailEditor;
