import { useContext, useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, isEmpty, handleControlValueChange, getParentData } from '../Core/SmartFunctions';
import ErrorControl from './ErrorControl';
import { onOrganizationChange } from '../../features/eventmanage/add/SpeakerInfoActions';

const SelectEditableOnchange = (args) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    
    // Initial selected option based on the current form state
    const data = getControlValueFromState(dataKey, state);
    const [selectedOption, setSelectedOption] = useState(data ? { value: data, label: data } : null);

    // Get parent data and domain category code for filtering options
    const [domainCategoryCode] = control.props.domainCategoryCode.split('#');
    const manualEntryColumn = control?.props?.customProperties?.manualEntryColumn;
    const manualEntryData = getControlValueFromState(`${parentDataKey}.${manualEntryColumn}`, state);
    const parentData = control.props.parentId && getParentData(parentDataKey + '.' + control.props.parentId, state);

    const action = state?.actions?.[control.id];

    // Memoize domain options to avoid recalculating on every render
    const options = useMemo(() => {
        const domainData = state?.domain?.get(domainCategoryCode) || [];
        const filteredDomainData = isEmpty(parentData)
            ? domainData
            : domainData.filter((domain) => domain.parentCode === parentData);
        
        return filteredDomainData.map((domain) => ({
            value: domain.code,
            lowerLabel: domain.value.toLowerCase(),
            label: domain.value,
        }));
    }, [state.domain, domainCategoryCode, parentData]);

    // Effect to handle initial selection or updates
    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey, state)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        setSelectedOption(
            options.find((option) => option.value === data) ?? { value: manualEntryData, label: manualEntryData }
        );
    }, [options, manualEntryData, control, dataKey, parentDataKey, dispatch]);

    // Function to load options dynamically based on user input
    const loadOptions = (inputValue, callback) => {
        if (inputValue.length < 3) {
            callback([]);  // Limit the results to those with at least 3 characters
        } else {
            callback(options.filter((option) => option.lowerLabel.includes(inputValue.toLowerCase())));
        }
    };

    // Handle option selection and dispatch the value to state
    const processOptionValue = (optionValue) => {
        const selectedOptionExistsInOptions = options.some((opt) => opt.value === optionValue);
        const activePayload = selectedOptionExistsInOptions
            ? { dataKey, name: control.id, value: optionValue, errorMessages: [] }
            : { dataKey: `${parentDataKey}.${manualEntryColumn}`, name: control.id, value: optionValue, errorMessages: [] };

        const inactivePayload = selectedOptionExistsInOptions
            ? { dataKey: `${parentDataKey}.${manualEntryColumn}`, name: control.id, value: null, errorMessages: [] }
            : { dataKey, name: control.id, value: null, errorMessages: [] };

        // Dispatch changes to state
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: activePayload });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: inactivePayload });

        // Update the selected option
        setSelectedOption(options.find((option) => option.value === optionValue) ?? { value: optionValue, label: optionValue });
    };

    // Handle change in selected value
    const handleChange = (option) => {
        console.log("option",control.id)
        processOptionValue(option?.value);

        if (action) {
            console.log(`control.id: ${control.id}`);
            action(option); 
        } else {
            console.log(`No action ${control.id}`);
        }
    };

    // Handle input change for search functionality
    const handleInputChange = (inputValue) => {
        if (inputValue.length >= 3) {
            processOptionValue(inputValue);
        }
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-text bg-white rounded-start">
                    <i className="bi bi-search fs-6"></i>
                </div>
                <div className="flex-fill form-control border-start-0 p-0">
                <AsyncSelect
                        loadOptions={loadOptions}
                        value={selectedOption}
                        onInputChange={handleInputChange}
                        onChange={(option) => {
                            handleChange(option);  // This calls processOptionValue
                            //onOrganizationChange(option);  // This triggers the alert
                        }}
                        isClearable
                        isSearchable
                        cacheOptions
                        noOptionsMessage={({ inputValue }) =>
                            inputValue.length < 3 ? 'Enter minimum three characters' : 'No options'
                        }
                    />

                </div>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectEditableOnchange;
