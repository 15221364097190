import axios from 'axios';
import { useContext, useEffect } from 'react';
import SessionContext from '../../../library/Core/SessionContext';

const HandleGoogleCallback = ({ pageTitle }: any) => {
    document.title = pageTitle;

    const { sessionState, sessionDispatch } = useContext(SessionContext);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        const schooldata = JSON.parse(localStorage.getItem('school_settings') ?? '{}');
        const schoolId = schooldata.id;
        const loginUrl = `${process.env.REACT_APP_APP_BASE_URL}/user/${schooldata.domain}/login`;
        const error = urlParams.get('error');

        if (error != undefined) {
            window.location.href = loginUrl;
            return;
        }

        if (!code) {
            console.error('Authorization code not found');
            window.location.href = loginUrl;
            return;
        }

        const fetchAccessToken = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/user/get-google-access-token`, { 'code' : code });
                // console.log(response, 'response');return;
                // console.log(JSON.stringify(response.data), 'success');

                if (response.data.success) {
                    const { token, googleId, email, firstName, lastName, profilePictureUrl } = response.data;
                    const profilePicture = profilePictureUrl ?? '';
                    const loggedInPlatform = 'Google';

                    const response1 = await axios.post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/user/check-social-login-user`, {
                        registeredEmailId: email,
                        loggedInPlatform,
                        googleId,
                        institute_id : schoolId
                    });

                    if (response1.data.success) {
                        if (response1.data.isUserPresent) {
                            const authResponse = await axios.post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/authenticate-social-login`, {
                                registeredEmailId: email
                            });

                            if (!authResponse.data.token) {
                                // dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [authResponse.data] });
                                alert(authResponse.data.message);
                                window.location.href = loginUrl;
                                return;
                            }

                            const profileCount = authResponse?.data?.institutes?.filter((institute: any) => institute?.userStatusCode !== 'INACTIVE').length ?? 0;

                            if (profileCount === 0) {
                                // dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [{ message: "Your profile is not active, Please contact administrator..!" }] });
                                alert("Your profile is not active, Please contact administrator..!");
                                window.location.href = loginUrl;
                                return;
                            }

                            sessionStorage.setItem('user', JSON.stringify(authResponse.data));
                            sessionStorage.setItem('user-jwt-token', authResponse.data.token);

                            localStorage.setItem('user', JSON.stringify(authResponse.data));
                            localStorage.setItem('user-jwt-token', authResponse.data.token);
                            localStorage.setItem('user-logged-out', 'FALSE');

                            sessionDispatch({ type: 'SET', payload: authResponse.data.data });
                            window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/select-institute`;
                        } else {
                            localStorage.setItem('googleToken', token);
                            localStorage.setItem('googleId', googleId);
                            localStorage.setItem('email', email);
                            localStorage.setItem('firstName', firstName);
                            localStorage.setItem('lastName', lastName);
                            localStorage.setItem('profilePicture', profilePicture);
                            localStorage.setItem('loggedInPlatform', loggedInPlatform);
                            window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/social-signup`;
                        }
                    }
                } else {
                    window.location.href = loginUrl;
                }
            } catch (error) {
                console.error(error);
                window.location.href = loginUrl;
            }
        };

        fetchAccessToken();
    }, [sessionDispatch]);

    return null;
};

export default HandleGoogleCallback;
